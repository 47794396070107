import React, { createContext, useContext, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const ScrollContext = createContext();

export function useScroll() {
  return useContext(ScrollContext);
}

export function ScrollProvider({ children }) {
  const contactRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  const scrollToContact = () => {
    if (contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  useEffect(() => {
    const shouldScroll = localStorage.getItem('scrollToContact') === 'true';

    if (shouldScroll) {
      if (location.pathname === '/') {
        const scrollTimeout = setTimeout(() => {
          scrollToContact();
          localStorage.removeItem('scrollToContact'); // Remove flag after scrolling
        }, 200); 

        return () => clearTimeout(scrollTimeout);
      } else {
        navigate('/', { replace: true });
      }
    }
  }, [location, navigate]);

  return (
    <ScrollContext.Provider value={{ scrollToContact, contactRef }}>
      {children}
    </ScrollContext.Provider>
  );
}
