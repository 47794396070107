import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import the navigate hook
import faqData from '../../data/faq.json';
import './styles/contact.css';
import SEO from '../../SEO';
function Faq() {
    const [faq, setFaq] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openIndex, setOpenIndex] = useState(null); // Track which question is open
    const navigate = useNavigate(); // Initialize the navigate hook

    useEffect(() => {
        // Assuming faqData is an array of FAQs
        setFaq(faqData);
        setLoading(false);
    }, []);

    const handleToggle = (index, question) => {
        setOpenIndex(prevIndex => prevIndex === index ? null : index); // Toggle openIndex
        
        // Check if the clicked question is "Contact Us"
        if (question.toLowerCase().includes('contact us')) {
            localStorage.setItem('scrollToContact', 'true'); // Set the scroll flag
            navigate('/secret'); // Navigate to the contact page
        }
    };

    if (loading) {
        return <div>Loading...</div>; // Simple loading state
    }

    return (
        <div className="faq w-screen h-auto md:px-[161px] px-[8%] pt-[200px]">
            <SEO
              title="Frequently Asked Questions - ChiaDia&#174;"
              description="Explore the FAQs about ChiaDia drinks, ingredients, health benefits, and more. Learn why our chia-based drinks are a healthy choice."
              keywords={['ChiaDia', 'FAQ', 'chia seeds', 'healthy drinks', 'chia-based products']}
              image="https://chiadia.com/images/faq.jpg"
              url="https://chiadia.com/faq"
            />
            <div className='faqElements flex flex-col'>
                <div className='title flex justify-start'>
                    <h1 className='md:text-[99px] text-[10.2vw] text-blackishText font-bold'>
                        Frequent Questions&nbsp;
                        <span className='text-ogGreenText'>
                            (FAQ)
                        </span>
                    </h1>
                </div>
                <div className='questions space-y-[24px] pt-[40px]'>
                    {faq.map((item, index) => (
                        <div key={index} className="nutritionalInfo pb-[16px] w-full md:max-w-[80%]">
                            <button
                                className={`w-full md:h-[39px] h-auto flex items-center justify-between px-[19px] py-[2.3%] transition-all duration-300 ${
                                    openIndex === index
                                        ? 'bg-white rounded-t-[25px] text-blackishText'
                                        : 'bg-white rounded-[25px] text-otherWhiteishText'
                                }`}
                                onClick={() => handleToggle(index, item.question)}
                            >
                                <h3 className={`md:text-[26px] text-[5vw] font-bold text-left ${openIndex === index ? 'text-blackishText' : 'text-blackishText'}`}>
                                    {item.question}
                                </h3>
                                <span className='text-[24px] font-bold text-ogGreenText'>
                                    {openIndex === index ? '-' : '+'}
                                </span>
                            </button>
                            <div className={`Info  ${openIndex === index ? 'open' : 'hidden'}`}>
                                <p className='bg-white py-[3%] text-[20px] leading-[25px] text-blackishText rounded-b-[25px] flex items-center justify-between px-[3%]'>
                                    {item.answer}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Faq;
