import React from 'react';
import './styles/findAsAt.css';

function AvailableAt() {
  return (
    <div className="w-screen flex items-center justify-center">
      <div className='flex items-center w-full justify-center md:w-[80%] md:px-[0px] px-[10%] w-full text-center md:py-[70px] py-[10%] md:pb-[50px] pb-[0%]'>
        <h5 className='md:text-[3.3vw] text-[3.3vw] text-ogGreenText font-bold md:leading-[90%] leading-[120%]'>
          <span className='text-blackishText'></span>
          AVAILABLE AT SUPERMARKETS, CAFES, GYMS, HEALTHY FOOD 
          <span className='hidden md:inline'>
          </span> 
           STORES, GAS STATIONS,
          <span className='font-black'>
             AND PRETTY MUCH &nbsp;
            <span className='hidden md:inline'>
            </span>  
            EVERYWHERE YOU NEED A BOOST!
          </span>
          <span className='text-blackishText'></span>
        </h5>
      </div>
    </div>
  );
}

export default AvailableAt;
