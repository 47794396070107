import React, { useState } from 'react';
import './SupplierAsset.css'; // Make sure the path is correct
import email from '../../assets/contact/email-icon-green.svg'; // Ensure the path is correct
import phone from '../../assets/contact/phone-icon-green.svg'; // Ensure the path is correct

function SupplierAsset() {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div 
            className={`w-screen h-[100%] flex absolute transition-all duration-700 ${isHovered ? 'left-[-510%]' : 'left-[-0%]'}`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div className='supplierAsset flex items-center align-start justify-start rounded-[2vw]'>
                <div className='shownSection relative left-[-7.3vw]'>
                    <p className="vertical-text text-[3.2vw] leading-[64%]">
                        GET IN TOUCH<br />
                        <span className="text-[2.2vw]">
                            WITH OUR TEAM
                        </span>
                    </p>
                </div>
                <div className='hiddenArea flex flex-col relative space-y-[2.5vh] right-[2vw]'>
                    <div className='flex flex-col items-center justify-center'>
                        <div className='w-[4.5vw] h-[4.5vw] rounded-full bg-white flex items-center justify-center'>
                            <img
                                src={email}
                                alt="Email Icon"
                                className="object-contain h-[2vw]"
                            />
                        </div>
                        <h4 className='text-newWhiteishText text-left text-[1.8vw] flex items-center justify-start pt-[0.2vh]'>
                            qinado@gmail.com
                        </h4>
                    </div>

                    <div className='flex flex-col items-center justify-center'>
                        <div className='w-[4.5vw] h-[4.5vw] rounded-full bg-white flex items-center justify-center'>
                            <img
                                src={phone}
                                alt="Phone Icon"
                                className="object-contain h-[1.8vw]"
                            />
                        </div>
                        <h4 className='text-newWhiteishText text-left text-[1.8vw] flex items-center justify-start pt-[0.2vh]'>
                            +39870210192
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SupplierAsset;
