import React, { useEffect, useState } from 'react';
import '../styles/productAssets.css';
import { ClipLoader } from 'react-spinners';

function NutritionalInfo({ product, isOpen, setIsOpen }) {
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    setLoading(false);
  }, [product]);

  if (loading) {
    return (
      <div className="spinner-container">
        <ClipLoader color="#4CAC57" loading={loading} size={150} />
      </div>
    );
  }
  if (!product) {
    return <div>Product not found</div>;
  }

  const handleToggle = () => {
    setIsOpen(prevState => !prevState);
  };

  // Function to calculate per 100ml based on the values per 250ml
  const calculatePer100ml = (valuePer250ml) => {
    // Parse the numeric value, assuming it follows the format like '6g' or '197kcal'
    const numericValue = parseFloat(valuePer250ml);
    if (isNaN(numericValue)) return valuePer250ml; // Return as is if parsing fails

    const unit = valuePer250ml.replace(/[0-9.]/g, '').trim(); // Extract the unit (g, kcal, etc.)
    const per100mlValue = (numericValue / 2.5).toFixed(1); // Convert to per 100ml
    return `${per100mlValue}${unit}`;
  };

  return (
    <div className="nutritionalInfo w-fit">
      <button
        className={`md:w-[466px] w-full h-[39px] rounded-[25px] flex items-center justify-between px-[17px] transition-all duration-300 ${
          isOpen 
            ? 'bg-mainBg border-[3px] border-ogGreenBorder text-ogGreenText' 
            : 'bg-ogGreenBg text-otherWhiteishText'
        }`}
        onClick={handleToggle}
      >
        <h3 className={`text-[22px] font-bold text-left ${isOpen ? 'text-ogGreenText' : 'text-otherWhiteishText'}`}>
          NUTRITIONAL INFO
        </h3>
        <span className='text-[24px] font-bold'>
          {isOpen ? 'x' : '+'}
        </span>
      </button>
      <div className={`Info pt-[14px] ${isOpen ? 'open' : ''}`}>
        <div className='flex flex-col space-y-2 pt-[14px] text-ogGreenText'>
          <div className='flex justify-between items-center font-bold text-[14px]'>
            <div className='flex-1 text-left px-2'>Typical Values</div>
            <div className='flex-1 text-left px-2'>Per 100ml</div>
            <div className='flex-1 text-left px-2'>Per 250ml</div>
          </div>
          <div className='h-[3px] bg-ogGreenBg'></div>
          <div className='flex justify-between items-center'>
            <div className='flex-1 text-left px-2'>Energy</div>
            <div className='flex-1 text-left px-2'>{calculatePer100ml(product.nutritionalInfo.energy.per250ml)}</div>
            <div className='flex-1 text-left px-2'>{product.nutritionalInfo.energy.per250ml}</div>
          </div>
          <div className='h-[1px] bg-ogGreenBg'></div>
          <div className='flex justify-between items-center'>
            <div className='flex-1 text-left px-2'>Fibre</div>
            <div className='flex-1 text-left px-2'>{calculatePer100ml(product.nutritionalInfo.fibre.per250ml)}</div>
            <div className='flex-1 text-left px-2'>{product.nutritionalInfo.fibre.per250ml}</div>
          </div>
          <div className='h-[1px] bg-ogGreenBg'></div>
          <div className='flex justify-between items-center'>
            <div className='flex-1 text-left px-2'>Protein</div>
            <div className='flex-1 text-left px-2'>{calculatePer100ml(product.nutritionalInfo.protein.per250ml)}</div>
            <div className='flex-1 text-left px-2'>{product.nutritionalInfo.protein.per250ml}</div>
          </div>
          <div className='h-[1px] bg-ogGreenBg'></div>
          <div className='flex justify-between items-center'>
            <div className='flex-1 text-left px-2'>Fat</div>
            <div className='flex-1 text-left px-2'>{calculatePer100ml(product.nutritionalInfo.fat.per250ml)}</div>
            <div className='flex-1 text-left px-2'>{product.nutritionalInfo.fat.per250ml}</div>
          </div>
          <div className='h-[1px] bg-ogGreenBg'></div>
          <div className='flex justify-between items-center'>
            <div className='flex-1 text-left px-2'>Saturated Fat</div>
            <div className='flex-1 text-left px-2'>{calculatePer100ml(product.nutritionalInfo.saturatedFat.per250ml)}</div>
            <div className='flex-1 text-left px-2'>{product.nutritionalInfo.saturatedFat.per250ml}</div>
          </div>
          <div className='h-[1px] bg-ogGreenBg'></div>
          <div className='flex justify-between items-center'>
            <div className='flex-1 text-left px-2 md:text-[0.85vw] text-[2vw]'>Carbohydrates</div>
            <div className='flex-1 text-left px-2'>{calculatePer100ml(product.nutritionalInfo.carbohydrates.per250ml)}</div>
            <div className='flex-1 text-left px-2'>{product.nutritionalInfo.carbohydrates.per250ml}</div>
          </div>
          <div className='h-[1px] bg-ogGreenBg'></div>
          <div className='flex justify-between items-center'>
            <div className='flex-1 text-left px-2'>Sugars</div>
            <div className='flex-1 text-left px-2'>{calculatePer100ml(product.nutritionalInfo.sugars.per250ml)}</div>
            <div className='flex-1 text-left px-2'>{product.nutritionalInfo.sugars.per250ml}</div>
          </div>
          <div className='h-[1px] bg-ogGreenBg'></div>
          <div className='flex justify-between items-center'>
            <div className='flex-1 text-left px-2'>Salt</div>
            <div className='flex-1 text-left px-2'>{calculatePer100ml(product.nutritionalInfo.salt.per250ml)}</div>
            <div className='flex-1 text-left px-2'>{product.nutritionalInfo.salt.per250ml}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NutritionalInfo;
