import React from 'react';
import insta from '../../assets/contact/new/instagram.svg'; 
import fb from '../../assets/contact/new/facebook.svg'; 
import linkedin from '../../assets/contact/new/linkedin.svg'; 

function Footer() {
  const handleContactClick = (e) => {
    e.preventDefault(); // Prevent default link behavior
    localStorage.setItem('scrollToContact', 'true'); // Set the flag in localStorage
    window.location.href = '/'; // Navigate to the homepage
  };

  return (
    <div className="footer-container w-screen h-auto flex flex-col md:px-[161px] px-[10%] pt-[130px] mt-[100px] md:pb-[0px] pb-[15%] ">
      <div className='footerContainer flex md:flex-row flex-col md:space-y-[0%] space-y-[25%]'>
        <div className='linksSection flex gap-[10%] w-full md:flex-row flex-col md:space-y-[0%] space-y-[10%]'>
          <div className='op flex flex-col'>
            <a href="#op-section">
              <h1 className='font-black md:text-[38px] text-[6vw] text-newWhiteishText pb-[5px]'>Our Products</h1>
            </a>
            <a className='font-medium md:text-[28px] text-[4vw] text-newWhiteishText' href="/product_details/0">Lime Detox</a>
            <a className='font-medium md:text-[28px] text-[4vw] text-newWhiteishText' href="/product_details/1">Strawberry Elixir</a>
            <a className='font-medium md:text-[28px] text-[4vw] text-newWhiteishText' href="/product_details/3">Red Currant Power C</a>
            <a className='font-medium md:text-[28px] text-[4vw] text-newWhiteishText' href="/product_details/2">Super Ginger</a>
          </div>
          <div className='learn flex flex-col'>
            <a href="#op-section">
              <h1 className='font-black md:text-[38px] text-[6vw] text-newWhiteishText pb-[5px]'>Learn</h1>
            </a>
            <a className='font-medium md:text-[28px] text-[4vw] text-newWhiteishText' href="/OurStory">Our Story</a>
            <a className='font-medium md:text-[28px] text-[4vw] text-newWhiteishText' href="/Blog">Blogs</a>
            <a className='font-medium md:text-[28px] text-[4vw] text-newWhiteishText' href="/WhyChiadia">Why ChiaDia?</a>
          </div>
          <div className='help flex flex-col'>
            <a href="#op-section">
              <h1 className='font-black md:text-[38px] text-[6vw] text-newWhiteishText pb-[5px]'>Help</h1>
            </a>
            <a className='font-medium md:text-[28px] text-[4vw] text-newWhiteishText' href="/faq">FAQ</a>
            <a 
              href="/contact" 
              onClick={handleContactClick} // Use the custom click handler
              className='font-medium md:text-[28px] text-[4vw] text-newWhiteishText'
            >
              Contact Us
            </a>
          </div>
        </div>
        <div className='mediaSection flex flex-col '>
          <a href="#op-section">
            <h1 className='font-black md:text-[38px] text-[6vw] text-newWhiteishText md:text-left text-center'>Follow Us</h1>
          </a>
          <div className='h-auto w-auto flex justify-center space-x-[30px] pt-[12px]'>
            <a 
              href="https://www.instagram.com/chiadiadrinks/" 
              target="_blank" 
              rel="noopener noreferrer"
              className='w-[54px] h-[54px] rounded-full bg-mainBg flex items-center justify-center'
            >
              <img src={insta} alt="Instagram" className='w-[36px] h-[36px]' />
            </a>
            
            <a 
              href="https://www.facebook.com/chiadiadrinks" 
              target="_blank" 
              rel="noopener noreferrer"
              className='w-[54px] h-[54px] rounded-full bg-mainBg flex items-center justify-center'
            >
              <img src={fb} alt="Facebook" className='w-[36px] h-[36px]' />
            </a>
            
            <a 
              href="https://www.linkedin.com/company/chiad%C3%ADa/" 
              target="_blank" 
              rel="noopener noreferrer"
              className='w-[54px] h-[54px] rounded-full bg-mainBg flex items-center justify-center'
            >
              <img src={linkedin} alt="LinkedIn" className='w-[28px] h-[28px]' />
            </a>
          </div>
        </div>
      </div>
      <div className='arr pb-[44px] md:pt-[0px] pt-[15%]'>
        <p className="footer-text text-newWhiteishText text-center md:md:text-[28px] text-[4vw] text-[4vw] font-normal mt-[20px]">
          © {new Date().getFullYear()} <span className='font-bold'>ChiaDia™ ,</span> All Rights Reserved
        </p>
      </div>
    </div>
  );
}

export default Footer;
