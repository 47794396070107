import React, { useEffect, useState } from 'react';
import '..//styles/productMain.css';
import sticker from '../../../assets/productSection/Asset 5@4x.png';

function BottleInCircle({ image }) {


  return (
    <div className="bc w-auto h-auto z-[0]">
        <div className='rightSection flex flex-1 items-center justify-center'>
          <div className="bigCircleWhite bg-white md:w-[35vw] md:h-[35vw] w-[65vw] h-[65vw] rounded-full relative flex items-center justify-center">
            <img
              src={sticker}
              alt="Non-GMO"
              className="object-cover relative w-[40%] md:top-[] top-[-38%] right-[-40%] z-[1] transform rotate-[-2deg]"
            />
            <img src={image} alt="Image" className="w-[30vw] align-center" />
          </div>
        </div>
    </div>
  );
}

export default BottleInCircle;
