import React from 'react';
import clockGreen from '../../assets/blog/timeGreen.png';
import clockWhite from '../../assets/blog/timeWhite.png';

function BlogTimeAsset({ isCard, time }) {
  return (
    <div
      className={`btAsset md:w-[180px] w-[90%] md:h-[40px] h-[3.3vh] rounded-[20px] ${
        isCard ? 'bg-ogGreenBg' : 'bg-whiteishBg'
      }`}
    >
      <div className='w-full h-full flex pl-[7px] pr-[15px] py-[5px] space-x-[8px] items-center justify-center'>
        {/* Conditionally render the clock image based on isCard */}
        <img
          src={isCard ? clockWhite : clockGreen} // Use clockWhite for card, clockGreen otherwise
          alt="Clock icon"
          className={`md:w-[1.5vw] w-[5.2vw]`} // Dynamically set the width based on screen size
        />
        <h5
          className={`font-bold ${
            isCard ? 'text-newWhiteishText md:text-[20px] text-[3vw]' : 'text-ogGreenText md:text-[20px] text-[3.3vw]'
          }`}
        >
          {time} min. read
        </h5> 
      </div>
    </div>
  );
}

export default BlogTimeAsset;
