import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 
import '../styles/productAssets.css';
import { FaArrowRight } from "react-icons/fa";

import arrowImage from '../../../assets/productSection/rArr.svg';

function ViewProducts({ product, colorClass, isOpen, imageSrc }) { 
  const navigate = useNavigate(); 
  isOpen = true;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const handleReadMore = () => {

    window.location.href = `/product_details/${product.id}`;

  };


  // Split the title into words
  const words = product.title.split(' ');

  // Determine the break index
  let breakIndex = 1;
  if (words[1] && words[1].toLowerCase() === 'currant') {
    breakIndex = 2;
  }

  // Split the title based on the break index
  const firstPart = words.slice(0, breakIndex).join(' ');
  const secondPart = words.slice(breakIndex).join(' ');

  const paddingClass = windowWidth >= 600 && windowWidth <= 768 ? 'pl-[16%]' : 'pl-[22.5%]';


  return (
    <div className={`vp flex gap-[1.04vw] justify-start md:pl-[0px] ${paddingClass} md:flex-row flex-col-reverse`}>
      <div className={`smallCircle ${colorClass} md:w-[12.08vw] md:h-[12.08vw] w-[50vw] h-[50vw]`}>
        <img src={imageSrc} alt={product.title} className="align-center flex" />
      </div>
      <div className="flex md:flex-col space-y-[0.94vh] items-left md:justify-start justify-between transition-all duration-300 flex-row md:pt-[2.3vw] md:pr-[0px] pr-[10%]">
        <h1 className={`text-blackishText md:text-[2.29vw] text-[5.6vw] leading-[88%] font-bold transition-all duration-300 md:pb-[2%] pb-[8%] md:pr-[0px]  ${isOpen ? '' : 'translate-y-[2.78vh]'}`}>
          {firstPart} <br /> {secondPart}
        </h1>
        <button 
          className={`md:h-[3.8vh] md:w-[7.5vw] w-[22vw] h-[3vh] rounded-[5.21vh] md:pr-[0px] mr-[0%] md:px-[0px] px-[1%] ${colorClass} transition-opacity duration-300 ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`} 
          onClick={handleReadMore}
        >
          <h4 className="text-otherWhiteishText md:text-[0.78vw] text-[2.3vw] font-bold w-full flex items-center justify-between px-[0.68vw]">
            Read More <img src={arrowImage} alt="arrow" className="ml-2 md:w-[1.1vw] w-[2vw] md:h-[2.8vh] h-[2vh]" />
          </h4>
        </button>
      </div>
    </div>
  );
}

export default ViewProducts;
