import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import CardOpen from './CardOpen';
import CardClosed from './CardClosed';
import sliderUp from '../../assets/productSection/slider_up.svg';
import './styles/productCard.css';

function ProductCard({ product }) {
  const [isHovered, setIsHovered] = useState(false);
  const [sliderTransform, setSliderTransform] = useState('');
  const [cardStatus, setCardStatus] = useState('closed'); // State for card status
  const sliderRef = useRef(null);
  const cardRef = useRef(null);
  const navigate = useNavigate(); // Use useNavigate

  const updateTransform = useCallback(() => {
    if (sliderRef.current) {
      const transform = getComputedStyle(sliderRef.current).transform;
      setSliderTransform(transform);

      // Check for collision
      if (cardRef.current && sliderRef.current) {
        const cardRect = cardRef.current.getBoundingClientRect();
        const sliderRect = sliderRef.current.getBoundingClientRect();
        if (sliderRect.top < cardRect.top && cardStatus !== 'open') {
          setCardStatus('open'); // Update status to open
        } else if (sliderRect.top >= cardRect.top && cardStatus !== 'closed') {
          setCardStatus('closed'); // Update status to closed
        }
      }

      requestAnimationFrame(updateTransform);
    }
  }, [cardStatus]);

  useEffect(() => {
    requestAnimationFrame(updateTransform);
    return () => cancelAnimationFrame(updateTransform);
  }, [updateTransform]);

  useEffect(() => {
    //console.log('Slider transform:', sliderTransform);
  }, [sliderTransform]);

  const handleReadMore = () => {
    // Navigate to the product details page with product ID as a URL parameter
    navigate(`/product_details/${product.id}`);
  };

  return (
    <div className="pc w-[523px] min-w-[523px] h-auto flex flex-col overflow-y-hidden relative">
      <div
        ref={cardRef}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className={`transition-container ${isHovered ? 'open' : 'closed'}`}
       >
        {cardStatus === 'open' ? <CardOpen product={product} /> : <CardClosed />}
        <img
          ref={sliderRef}
          src={sliderUp}
          alt="Slider Up"
          className={`slider-up ${isHovered ? 'slide-up' : ''}`}
        />
      </div>
      <div className="info w-full flex flex-col items-center space-y-[28px] bg-ogGreenBg z-10">
        <div className="h-[75px] w-fit mt-[5px]">
          <h2 className="text-[63px] text-whiteishText font-black">Power C</h2>
        </div>
        <button className="h-[53px] w-[216px] rounded-[100px] bg-white" onClick={handleReadMore}>
          <h4 className="text-ogGreenText text-[29px] font-bold">Read More</h4>
        </button>
      </div>
    </div>
  );
}

export default ProductCard;
