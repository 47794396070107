import React from 'react';
import productData from '../../data/productCardData.json';
import ProductSection from './ProductSection';

function ProductList() {
  return (
    <div className="product-list w-auto">
      {productData.map((product, index) => (
        <ProductSection
          key={product.id}
          id={product.id}
          nextId={productData[index + 1]?.id}
          isLast={index === productData.length - 1} // Check if it's the last section
        />
      ))}
    </div>
  );
}

export default ProductList;

//RESPONSIVE DONE