import React, { useState, useEffect } from 'react';
import waveGreen from '../../assets/productSection/brane.svg'; 
import waveRed from '../../assets/productSection/brane2.svg'; 
import waveYellow from '../../assets/productSection/brane3.svg'; 
import wavePink from '../../assets/productSection/brane4.svg'; 
import './styles/productSection.css';
import ProductMain from './ProductMain';

const waveData = {
  0: { image: waveGreen, color: '#4CAC57' }, // Green
  1: { image: waveRed, color: '#FF5359' },   // Red
  2: { image: waveYellow, color: '#F5CC42' }, // Yellow
  3: { image: wavePink, color: '#F83D4C' },   // Pink
};

function ProductSection({ id, nextId, isLast }) {
  const [isMobile, setIsMobile] = useState(false);
  const [heightValues, setHeightValues] = useState({
    containerHeight: '120vh',
    imageHeight: '125vh',
  });

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      // Mobile-specific height adjustments
      if (width >= 470 && width <= 575) {
        setHeightValues({
          containerHeight: '110vh',
          imageHeight: '115vh',
        });
      } else if (width >= 575 && width <= 665) {
        setHeightValues({
          containerHeight: '140vh',
          imageHeight: '130vh',
        });
        
      }else if (width >= 666 && width <= 767) {
        setHeightValues({
          containerHeight: '150vh',
          imageHeight: '145vh',
        });
        
      }
       else {
        // Default heights for larger screen sizes
        setHeightValues({
          containerHeight: '90vh',
          imageHeight: '90vh',
        });
      }

      setIsMobile(width <= 768);
    };

    handleResize(); // Check screen size on initial render
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const waveImage = waveData[id]?.image || "#FFFFFF";
  const bgColor = waveData[id]?.color || '#4CAC57';
  const nextBgColor = isLast ? '#F8F7F0' : (waveData[nextId]?.color || '#4CAC57');

  return (
    <div
      className="ps relative w-screen h-[100vh] md:h-[120vh] mt-[12%] md:mt-[0px]"
      style={{
        background: `linear-gradient(${nextBgColor}, ${nextBgColor})`,
        height: isMobile ? heightValues.containerHeight : '120vh', // Override height only for mobile
      }}
    >
      <div className="flex w-full h-full flex-col">
        <div>
          <img
            src={waveImage}
            alt="Background"
            className={`absolute md:h-[120vh] h-[95vh] w-auto max-w-none ${isMobile ? 'animated-img-mobile' : 'animated-img'}`}
            style={{ height: isMobile ? heightValues.imageHeight : '120vh' }} // Override height only for mobile
          />
        </div>
        <div className="relative w-full h-full md:flex-row z-10 md:bottom-[0%] bottom-[7%]">
          <ProductMain id={id} bgColor={bgColor} />
        </div>
      </div>
    </div>
  );
}

export default ProductSection;
