import React from 'react';
import email from '../../assets/contact/email-icon.svg'; // Ensure the path is correct
import phone from '../../assets/contact/phone-icon.svg'; // Ensure the path is correct
import pin from '../../assets/contact/location pin.svg'; // Ensure the path is correct

import './styles/contact.css';

import SupplierAsset from './SupplierAsset';
import SupplierAssetMobile from './SupplierAssetMobile';

function Contact() {

    const handleReadMore = () => {
      };

  return (
    <div className="co w-screen h-auto">
        <div className='contact md:pl-[8.5vw] px-[8.5vw] pb-[0px] md:pr-[0px] py-[7vh] flex w-full justify-between flex-col md:flex-row'>
            <div className='leftSection pt-[3.2vh] flex flex-col flex-1 md:w-[50%] items-start md:border-r-[0.05vw] md:border-b-[0vw] border-b-[0.01vw] md:pb-[0px] pb-[8%] border-blackishBorder'>
                <h1 className='text-blackishText md:text-[4.5vw] text-[10vw] font-bold leading-[94%]'>TALK<br/>WITH US</h1>
                <div className='contactDetails pt-[2.5vh] space-y-[1.2vh] w-full md:w-auto'>
                    <div className='md:gap-[2vh] gap-[0vw] flex md:flex-col flex-row justify-between w-full'>
                        <h4 className='text-blackishText text-left md:text-[1.8vw] text-[3vw] flex items-center justify-start'>
                            <span className='pr-[1.1vw]'>
                            <img
                                src={phone}
                                alt="phoneIco"
                                className="md:w-[1.6vw] w-[3vw]"
                            />
                            </span>
                            +38970210192
                        </h4>
                        <h4 className='text-blackishText text-left md:text-[1.8vw] text-[3vw] flex items-center justify-start md:pr-[0%] pr-[14.6%]'>
                            <span className='pr-[1.1vw]'>
                            <img
                                src={phone}
                                alt="phoneIco"
                                className="md:w-[1.6vw] w-[3vw]"
                            />
                            </span>
                            +38970517703
                        </h4>
                    </div>
                    <div className='md:gap-[2vh] gap-[0vw] flex md:flex-col flex-row justify-between w-full'>
                        <h4 className='text-blackishText text-left md:text-[1.8vw] text-[3vw] flex items-center justify-start'>
                            <span className='pr-[1.1vw]'>
                            <img
                                src={email}
                                alt="emailIco"
                                className="md:w-[1.6vw] w-[3vw]"
                            />
                            </span>
                            info@chiadia.com
                        </h4>
                        <h4 className='text-blackishText text-left md:text-[1.8vw] text-[3vw] flex items-center justify-start'>
                            <span className='pr-[1.1vw]'>
                            <img
                                src={email}
                                alt="emailIco"
                                className="md:w-[1.6vw] w-[3vw]"
                            />
                            </span>
                            contact@chiadia.com
                        </h4>
                    </div>
                    <div className='md:gap-[2vh] pt-[1vh] gap-[0vw] flex md:flex-col flex-row justify-between w-full'>
                        <h4 className='text-blackishText text-left md:text-[1.8vw] text-[3vw] flex items-center justify-start'>
                            <span className='pr-[1.1vw]'>
                            <img
                                src={pin}
                                alt="pinIco"
                                className="md:w-[1.6vw] w-[3vw]"
                            />
                            </span>
                            11 Kongres 53, Stip 2000
                        </h4>
                    </div>
                </div>
            </div>
            <div className="rightSection pt-[3.2vh] pr-[1.1vw] flex flex-col flex-1 md:w-[50%] items-end md:border-l-[0.05vw] border-blackishBorder">
                <div className='md:w-fit w-full pr-[2.4vw]'>
                    <h1 className="text-blackishText md:text-[4.5vw] text-[10vw] font-bold leading-[94%]">LET’S CHAT —<br>
                    </br>DRINKS ON US
                    </h1>
                    <div className="contactForm pt-[2.5vh] space-y-[1.5vh] md:w-[32vw]">
                        <div className="flex space-x-[1.2vw]">
                            <input 
                                type="text" 
                                placeholder="Name" 
                                className="w-1/2 p-[1vw] border rounded-[1vw] md:text-[1.2vw] text-blackishText"
                            />
                            <input 
                                type="tel" 
                                placeholder="Tel" 
                                className="w-1/2 p-[1vw] border rounded-[1vw] md:text-[1.2vw] text-blackishText"
                            />
                        </div>
                        <div>
                            <input 
                                type="email" 
                                placeholder="Email" 
                                className="w-full p-[1vw] border rounded-[1vw] md:text-[1.2vw] text-blackishText"
                            />
                        </div>
                        <div>
                            <textarea 
                                placeholder="Your message" 
                                className="w-full p-[1vw] border rounded-[1vw] md:text-[1.2vw] text-blackishText h-[20vh]"
                            ></textarea>
                        </div>
                        <div className='w-full flex justify-end'>
                            <button className="md:h-[44px] h-[3.4vh] md:w-[10vw] w-[20vw] md:rounded-[2vw] rounded-[3vw] bg-ogGreenBg " onClick={handleReadMore}>
                                <h4 className="text-otherWhiteishText md:text-[1.4vw] font-medium">SEND</h4>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* On larger screens show SupplierAsset, on mobile show SupplierAssetMobile */}
            <div className='h-[35vw] w-[8%] relative rounded-s-[1.5vw] flex align-start justify-start hidden md:flex'>
                <SupplierAsset />
            </div>
            <div className='h-[45vw] w-full relative flex align-start justify-start flex md:hidden mt-[5%]'>
                <SupplierAssetMobile />
            </div>
        </div>
    </div>
  );
}

export default Contact;
