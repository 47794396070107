import React, { useEffect, useState } from 'react';

import './styles/whyChiaDia.css';

import limeDetox from '../../assets/Our Story - Why ChiaDia/Bottles/b1.png';
import strawberryElixir from '../../assets/Our Story - Why ChiaDia/Bottles/b3.png';
import superGinger from '../../assets/Our Story - Why ChiaDia/Bottles/b4.png';
import redCurrantPowerC from '../../assets/Our Story - Why ChiaDia/Bottles/b2.png';

import lime from '../../assets/Our Story - Why ChiaDia/Bottles/lime 2 down right.png';
import strawberry from '../../assets/Our Story - Why ChiaDia/Bottles/strawberry down right.png';
import ginger from '../../assets/Our Story - Why ChiaDia/Bottles/ginger up left.png';
import currant from '../../assets/Our Story - Why ChiaDia/Bottles/red currant down left.png';

import waveyImage from '../../assets/Our Story - Why ChiaDia/The-power-of-our-super-ingredients-bg.png';
import stars from '../../assets/Our Story - Why ChiaDia/uspHere/allInOne.png';

import eco from '../../assets/Our Story - Why ChiaDia/100_ eco friendly.png';

import usp1 from '../../assets/Our Story - Why ChiaDia/uspHere/One by one/1.svg';
import usp2 from '../../assets/Our Story - Why ChiaDia/uspHere/One by one/2.svg';
import usp3 from '../../assets/Our Story - Why ChiaDia/uspHere/One by one/3.svg';
import usp4 from '../../assets/Our Story - Why ChiaDia/uspHere/One by one/4.svg';
import usp5 from '../../assets/Our Story - Why ChiaDia/uspHere/One by one/5.svg';
import SEO from '../../SEO'; 


function OurStory() {

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // Function to update the screen width state
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  // Effect to add an event listener on mount and clean up on unmount
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Determine dynamic classes based on screen width
  const dynamicTopValue = () => {
    if (screenWidth >= 1631) return 'md:top-[-0%]';
    if (screenWidth <= 1630) return 'md:top-[-0%]';
    if (screenWidth <= 1450) return 'md:top-[-0%]';
    return 'top-[-60%]'; // Default top value for other sizes
  };


  return (
    <div className="w-screen h-full overflow-hidden">
      <SEO
        title="Why ChiaDia&#174; - ChiaDia's Healthy Ingredients & Sustainability"
        description="Explore Why ChiaDia: Discover the power of chia seeds, eco-friendly packaging, and our commitment to sustainability with delicious, healthy drinks."
        keywords={['ChiaDia', 'Why ChiaDia', 'healthy drinks', 'chia seeds', 'sustainability', 'eco-friendly', 'recyclable glass bottles']}
        image="https://chiadia.com/images/why-chiadia.jpg"
        url="https://chiadia.com/WhyChiaDia"
      />

      <div className='mainCont flex flex-col'>
        <div className='topSection mt-[9vh] flex flex-col items-center justify-center md:mb-[0vh] '>
          <div className='topImages flex md:space-x-[3.5vw] pl-[4vw] md:pb-[4vh] pb-[1vh]'>
            <img 
              src={superGinger} 
              alt="ImageBottle" 
              className="relative max-w-[25vw] h-auto object-contain" 
            />
            <img 
              src={lime} 
              alt="ImageBottle" 
              className="relative max-w-[25vw] h-auto object-contain" 
            />
            <img 
              src={limeDetox} 
              alt="ImageBottle" 
              className="relative max-w-[25vw] h-auto object-contain" 
            />
            <img 
              src={ginger} 
              alt="ImageBottle" 
              className="relative max-w-[25vw] h-auto object-contain" 
            />
          </div>

          <div className='centerText w-full h-full flex items-center justify-center md:pb-[7vh] pb-[2vh]'>
            <div className='absolute w-[80%]'>
              <h2 className='text-ogGreenText font-bold md:text-[7.3vw] text-[8vw] text-center leading-[6vw]'>
                We don’t sell drinks<br></br> We deliver benefits.
              </h2>
            </div>
          </div>

          <div className='topImages flex md:space-x-[3.5vw] pl-[4vw]'>
            <img 
              src={currant} 
              alt="ImageBottle" 
              className="relative max-w-[25vw] h-auto object-contain  md:w-auto w-[5rem]" 
            />
            <img 
              src={strawberryElixir} 
              alt="ImageBottle" 
              className="relative max-w-[30vw] h-auto object-contain  md:w-auto w-[6rem]" 
            />
            <img 
              src={redCurrantPowerC} 
              alt="ImageBottle" 
              className="relative max-w-[30vw] h-auto object-contain  md:w-auto w-[6rem]" 
            />
            <img 
              src={strawberry} 
              alt="ImageBottle" 
              className="relative max-w-[25vw] h-auto object-contain md:w-auto w-[4rem]" 
            />
          </div>
        </div>

        <div className="middleSectionWhy w-screen md:h-[114vh] h-[40%] relative pb-[5vh] md:pb-[0px] md:bg-mainBg bg-ogGreenBg md:mt-[0px]">
          <div className="mdMain flex px-[8.3vw] relative z-10 lg:mt-[1%] pt-[7%] md:pt-[0%] md:mt-[-6%] sm:mt[-200%] md:flex-row flex-col items-center">
            <div className="textSection flex flex-col w-[90%] md:pb-[0px] pb-[9%]">
              <h2 className="text-newWhiteishText font-bold md:text-[4.5vw] text-[5.6vw] leading-[90%] pb-[1.5vh]">
                THE POWER OF OUR SUPER HEALTHY INGREDIENTS
              </h2>
              <p className="text-newWhiteishText font-[300] text-[2.9vw] leading-[85%] md:text-[1.47vw] md:leading-[110%] w-[80%]">
                As pragmatic believers in natural ingredients, we set out to create not just the tastiest but also the most premium functional drinks. Our ChiaDia beverages are infused with top-tier chia seeds, providing plant-based protein, essential vitamins, and antioxidants. Designed to boost your immunity, enhance digestion, and support overall health, ChiaDia is where health meets delicious. With premium ingredients and an energizing boost, it’s a drink that’s as nourishing as it is delightful, a drink to truly adore.
              </p>
              <h5 className="text-newWhiteishText font-bold text-[3.4vw] w-[80%] leading-[3vw] pt-[1.5vh] md:text-[2.72vw] md:leading-[2.9vw]">
                Seriously, why not? It’s pure, nutritious, and delicious—what’s not to love?
              </h5>
            </div>
            <div className="starsSection relative left-[1.5rem] md:block hidden">
              <img
                src={stars}
                className="relative max-w-[10vw] h-auto object-contain"
              />
            </div>
            <div className="starsSection t-[15%] md:hidden flex items-center justify-between w-full space-">
              <img
                src={usp1}
                className="relative max-w-[10vw] h-auto object-contain"
              />
              <img
                src={usp2}
                className="relative max-w-[10vw] h-auto object-contain"
              />
              <img
                src={usp3}
                className="relative max-w-[10vw] h-auto object-contain"
              />
              <img
                src={usp4}
                className="relative max-w-[10vw] h-auto object-contain"
              />
              <img
                src={usp5}
                className="relative max-w-[10vw] h-auto object-contain"
              />
            </div>
          </div>
        </div>


        <div className='bottomSection relative md:top-[-13vh] md:pt-[0px] pt-[0%] md:space-y-[15vh]'>
          <div className='weirdSection flex flex-col h-full '>
            <div className="containerSvg mb-[2%] pb-[10%] md:pb-[0px] ">
              <svg
                width="fit-content" 
                height="100%" 
                viewBox="0 40 600 150" 
                xmlns="http://www.w3.org/2000/svg"
                className="arch-text flex items-center justify-center md:bottom-[0px] bottom-[10%]"
              >
                <defs className='flex items-center justify-center'>
                  <path
                    id="fullWidthArcPath"
                    d="M 80,185 A 450,800 0 0,1 500,185"
                    fill="green"
                    className='z-99999 flex items-center'
                  />
                </defs>
                <text fill="#4CAC57"  fontWeight="bold">
                  <textPath href="#fullWidthArcPath" startOffset="50%" textAnchor="middle" className='font-bold md:text-[1.5vw] text-[8vw] text-ogGreenText tracking-[0.15vw] relative '>
                    THE SUSTAINABLE TOUCH
                  </textPath>
                </text>
              </svg>
            </div>
            <div className='w-full md:h-full h-[30vh] flex md:items-center items-end justify-center absolute top md:mt-[0px] mt-[5%]'>
            <img 
                src={eco} 
                alt="Eco Friendly" 
                className="relative object-contain md:w-[13vw] w-[30%] max-w-full max-h-full bottom-[10vh] bottom-[8%] sm:bottom-[15vh] md:bottom-[10%]"
            />
            </div>
          </div>

          <div className='textSection w-full h-full flex items-center justify-center md:px-[12.9vw] px-[8%]'>
            <p className='md:text-[1.97vw] text-[4vw] text-blackishText font-[300] text-center md:leading-[2.08vw] leading-[98%]'>
            <span className='font-medium text-ogGreenText'>
            At ChiaDia, we’re not just about crafting amazing drinks; we’re also about making a positive impact on the planet. &#8203;
            </span>
            We proudly &#8203;
            <span className='font-medium text-ogGreenText'>
            use eco-friendly, recyclable glass bottles &#8203;
            </span>
            and harness solar power to reduce our carbon footprint. Committed to zero waste, our production processes ensure nothing goes to waste. &#8203;
            <span className='font-medium text-ogGreenText'>
            We’ve also won awards for our innovation and dedication to sustainability. &#8203;
            </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurStory;
