import React, { useState, useEffect, useRef } from 'react';
import './styles/hero.css';
import heroAnimation from '../../assets/hero/finalHero.mp4';

function Hero() {
  const [isLoading, setIsLoading] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const loadingTimeoutRef = useRef(null);
  const videoTimeoutRef = useRef(null);
  const videoRef = useRef(null); // Ref for the video element

  useEffect(() => {
    const videoElement = document.createElement('video');
    videoElement.src = heroAnimation;
    videoElement.muted = true;
    videoElement.preload = 'auto';

    // Start loading the video data
    videoElement.load();

    // Progress simulation using requestAnimationFrame for smoother loading UI
    const simulateProgress = () => {
      setLoadingProgress((prev) => {
        if (prev >= 100) return 100;
        return prev + 1.67; // Adjust speed for smooth effect
      });
      loadingTimeoutRef.current = requestAnimationFrame(simulateProgress);
    };

    loadingTimeoutRef.current = requestAnimationFrame(simulateProgress);

    // Event for when the video is buffered enough to play
    videoElement.oncanplaythrough = () => {
      setIsLoading(false);
      cancelAnimationFrame(loadingTimeoutRef.current);
    };

    // Set a fallback timeout in case oncanplaythrough isn't triggered within 3s
    videoTimeoutRef.current = setTimeout(() => {
      if (isLoading) {
        setIsLoading(false);
        cancelAnimationFrame(loadingTimeoutRef.current);
      }
    }, 3000);

    videoRef.current = videoElement;

    // Disable scrolling while loading
    document.body.style.overflow = 'hidden';

    return () => {
      cancelAnimationFrame(loadingTimeoutRef.current);
      clearTimeout(videoTimeoutRef.current);
      document.body.style.overflow = ''; // Re-enable scrolling
    };
  }, [isLoading]);

  return (
    <div className='w-full h-fit relative'>
      {isLoading && (
        <div className='loadingScreen fixed inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50 backdrop-blur-md z-[99999]'>
          <div className='w-1/4 h-8 bg-gray-300 rounded-full overflow-hidden'>
            <div
              className='h-full bg-[#4CAC57] transition-all duration-100'
              style={{ width: `${loadingProgress}%` }}
            />
          </div>
          <p className='text-white text-lg mt-2'>{Math.round(loadingProgress)}%</p>
        </div>
      )}

      {!isLoading && (
        <video
          ref={videoRef}
          className='videoBackground relative w-full h-full object-cover'
          autoPlay
          loop
          muted
          playsInline
        >
          <source src={heroAnimation} type='video/mp4' />
          Your browser does not support the video tag.
        </video>
      )}
    </div>
  );
}

export default Hero;
