import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link'; 
import { useScroll } from '../../context/context';
import fig from '../../assets/navbar/Polu-krug.svg';
import lgLeaf from '../../assets/navbar/chia-dia-web-logos/leaf-logo.svg';
import lgDia from '../../assets/navbar/chia-dia-web-logos/chia-dia.svg';
import navbarDesign from '../../assets/navbar/Nav-bar-TAB/Nav-bar.png';

function Navbar() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [showLogo, setShowLogo] = useState(false);
  const [useNavbarDesign, setUseNavbarDesign] = useState(false); 
  const [isAtTop, setIsAtTop] = useState(true);
  const menuRef = useRef(null);
  const navigate = useNavigate();
  const { contactRef } = useScroll(); 

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const menuHeight = menuRef.current ? menuRef.current.clientHeight : 0;

      setIsAtTop(currentScrollPos === 0);

      if (currentScrollPos <= prevScrollPos) {
        setIsScrolled(false);
        setShowLogo(false);
        setUseNavbarDesign(true);
      } else if (currentScrollPos >= menuHeight) {
        setIsScrolled(true);
        setShowLogo(true);
        setUseNavbarDesign(false);
      }

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  const handleLogoClick = () => {
    window.scrollTo(0, 0);
    navigate('/');
  };

  const handleContactClick = (e) => {
    e.preventDefault(); // Prevent default link behavior
    localStorage.setItem('scrollToContact', 'true'); // Set the flag in localStorage
    window.location.href = '/'; // Navigate to the homepage
  };

  return (
    <div className="w-full z-[99998]">
      <div
        ref={menuRef}
        className={`z-[99999] w-full fixed h-[80px] transition-opacity duration-300 ${
          isScrolled && !isAtTop ? 'hidden' : 'opacity-100'
        }`}
      >
        <div
          className={`absolute top-0 w-full h-full ${
            isScrolled && isAtTop ? 'hidden' : 'opacity-70'
          }`}
          style={{
            background: (isAtTop || isScrolled) ? '#F7F9F9' : useNavbarDesign && !isAtTop ? `url(${navbarDesign}) no-repeat center center/cover` : 'transparent',
            opacity: isAtTop ? 0.7 : 1,
            backdropFilter: 'blur(36px)',
            transition: 'opacity 0.1s, background-color 0.3s',
          }}
        />
        <div className={`menu w-full h-full flex justify-between items-start text-[1.6vw] ${useNavbarDesign && !isAtTop ? 'text-newWhiteishText' : 'text-navText'}`}>
          <div className="leftSc h-full items-center flex-1 flex justify-end space-x-[80px] relative">
            <a href="/OurStory" className={`p-2 font-[300] transition-all duration-300 ${useNavbarDesign && !isAtTop ? 'hover:text-whiteishText hover:border-whiteishBorder' : 'hover:text-navHoverText hover:border-[#2D3A45]'}`}>
              Our Story
            </a>
            <a href="/WhyChiadia" className={`p-2 font-[300] transition-all duration-300 ${useNavbarDesign && !isAtTop ? 'hover:text-whiteishText hover:border-whiteishBorder' : 'hover:text-navHoverText hover:border-[#2D3A45]'}`}>
              Why ChiaDia
            </a>
          </div>
          <div className="logoSc flex-1 relative flex justify-center items-center cursor-pointer" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} onClick={handleLogoClick}>
            <img src={fig} alt="Logo Background" className="absolute w-[12vw] top-0" />
            <img src={lgLeaf} alt="Logo Text" className="relative w-[12vw] transition-opacity duration-300" />
          </div>
          <div className="rightSc h-full items-center flex flex-1 justify-start space-x-[80px] relative">
            <a href="/blog" className={`p-2 font-[300] transition-all duration-100 ${useNavbarDesign && !isAtTop ? 'hover:text-whiteishText hover:border-whiteishBorder' : 'hover:text-navHoverText hover:border-[#2D3A45]'}`}>
              Blog
            </a>
            {/* Updated to call handleContactClick */}
            <a onClick={handleContactClick} className={`p-2 font-[300] cursor-pointer transition-all duration-100 ${useNavbarDesign && !isAtTop ? 'hover:text-whiteishText hover:border-whiteishBorder' : 'hover:text-navHoverText hover:border-[#2D3A45]'}`}>
              Contact
            </a>
          </div>
        </div>
      </div>
      <div 
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={handleLogoClick}
        className={`fixed z-[99999] top-0 w-full flex justify-center cursor-pointer items-center transition-all opacity-100 duration-100 ${isScrolled ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
        <img src={fig} alt='Logo Background' className='absolute w-[12vw] top-0' />
        <img src={isScrolled ? lgLeaf : lgDia} alt='Logo Text' className={`relative transition-all ${isScrolled ? 'w-[11vw]' : 'w-[11vw]'}`} />
      </div>
    </div>
  );
}

export default Navbar;
