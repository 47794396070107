import React, { useEffect } from 'react';
import { FaArrowRight } from "react-icons/fa";
import bp from '../../assets/blog/blog1.jpg'; // Default image
import BlogTimeAsset from './BlogTimeAsset';

import arrowImage from '../../assets/productSection/rArrGreen.svg';

// Image map to associate each blog ID with its image URL
const blogImages = {
  1: require('../../assets/blog/BLOGS PHOTOS/1blog/1.png'),
  2: require('../../assets/blog/BLOGS PHOTOS/2blog/2.png'),
  3: require('../../assets/blog/BLOGS PHOTOS//3blog/3.png'),
  4: require('../../assets/blog/BLOGS PHOTOS/4blog/4.png'),
  5: require('../../assets/blog/BLOGS PHOTOS/5blog/5.png'),
};

// Function to calculate estimated reading time
const calculateReadingTime = (content) => {
  const wordsPerMinute = 200; // Average reading speed
  const wordCount = content.reduce((acc, para) => acc + para.paragraph.split(' ').length, 0);
  const minutes = Math.ceil(wordCount / wordsPerMinute);
  return minutes;
};

function BlogCard({ blog, onReadMore, isCard }) {
  const readingTime = calculateReadingTime(blog.paragraphs);

  useEffect(() => {
    console.log(readingTime);
    console.log(blog.paragraphs);
    console.log(isCard);
  }, [readingTime, blog, isCard]);

  // Dynamically get the blog image or fallback to default
  const blogImage = blogImages[blog.id] || bp;

  return (
    <div className="bc max-w-[464px] md:h-[640px] rounded-[33px] overflow-hidden">
      <div className='absolute p-[20px] w-[180px] h-[30px] md:ml-[245px] ml-[43%]'>
        <BlogTimeAsset isCard={isCard} time={readingTime} />
      </div>
      <div className='mainCont w-full flex flex-col rounded-[33px]'>
      <div className='topSection w-full md:h-[410px] max-h-[50vh] flex items-center rounded-t-[33px] overflow-hidden'>
        <img
          src={blogImage} // Dynamically loaded image
          alt="cardItem"
          className="h-full w-full object-cover" // Change here
          style={{ maxHeight: '100%', maxWidth: '100%' }} // Ensure it doesn't exceed container
        />
      </div>

        <div className={`bottomSection md:h-[230px] h-fit flex flex-col ${isCard ? 'justify-between' : 'justify-center'} w-full bg-ogGreenBg pt-[22px] pb-[43px] pl-[20px] pr-[65px] space-y-[24px] rounded-b-[33px]`}>
          <h1 className='text-newWhiteishText md:text-[28px] text-[5.5vw] font-bold md:leading-[26px] leading-[100%]'>
            {blog.title}
          </h1>
          {isCard && (
            <button 
              className={`md:h-[41px] h-[20%] md:w-[144px] w-[44%] rounded-[100px] bg-whiteishBg transition-opacity duration-300 opacity-100`} 
              onClick={onReadMore}
            >
              <h4 className="text-ogGreenText md:text-[15px] text-[3.2vw] font-bold w-full flex items-center justify-between px-[13px]">
                Read More   <img src={arrowImage} alt="arrow" className="ml-2 w-[1.3vw] h-[2.8vh]" />

              </h4>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default BlogCard;
