import React from 'react';
import './styles/usp.css';

import usp1 from '../../assets/USP/Asset 1.svg';
import usp2 from '../../assets/USP/Asset 2.svg';
import usp3 from '../../assets/USP/Asset 3.svg';
import usp4 from '../../assets/USP/Asset 4.svg';
import usp5 from '../../assets/USP/Asset 5.svg';
import usp6 from '../../assets/USP/Asset 6.svg';
import usp7 from '../../assets/USP/Asset 7.svg';
import usp8 from '../../assets/USP/Asset 8.svg';
import usp9 from '../../assets/USP/Asset 9.svg';
import usp10 from '../../assets/USP/Asset 10.svg';
import usp11 from '../../assets/USP/Asset 11.svg';

import uspBackground from '../../assets/USP/usps bar.svg';

function Usp() {
  const images = [usp1, usp2, usp3, usp4, usp5, usp6, usp7, usp8, usp9, usp10, usp11];

  // Create a larger set of elements by repeating the images multiple times
  const repeatedImages = Array(11).fill(images).flat();

  // Include the "Find Us At" text at intervals
  const allElements = [{ type: 'text' }, ...repeatedImages.map((image, index) => ({ type: 'image', src: image, index }))];

  // Duplicate the elements to ensure smooth scrolling
  const infiniteElements = [...allElements, ...allElements, ...allElements, ...allElements, ...allElements, ...allElements, ...allElements, ];

  return (
    <div className="w-screen h-[211px] flex items-center overflow-hidden">

      <div className="infiniteSlider flex items-center justify-start animate-scroll md:gap-[6%] gap-[5%] relative left-[-50%]">
        {infiniteElements.map((element, index) => 
          element.type === 'text' ? (
            <h2 key={index} className="text-[54px] text-bold text-ogGreenText font-bold whitespace-nowrap text-left align-start ">
              Find Us At
            </h2>
          ) : (
            <img
              key={index}
              src={element.src}
              alt={`uspation ${index % images.length + 1}`}
              className={`object-contain ${[0, 7].includes(element.index) ? 'md:w-[300px] md:h-[300px] h-[200px] w-[200px]' : 'md:w-[280px] md:h-[190px] w-[100px] h-[100px]'}`}
            />
          )
        )}
      </div>
    </div>
  );
}

export default Usp;
