import React, { useState, useEffect } from 'react';
import Hero from './hero/Hero';
import FillerOne from './hero/FillerOne';
import ProductsList from './products/ProductsList';
import Contact from './contact/Contact';
import Social from './contact/Social';
import Usp from './usp/Usp';
import FindAsAt from './findUs/FindAsAt';
import AvailableAt from './findUs/AvailableAt';
import { useScroll } from '../context/context'; // Import the context

import SEO from '../SEO';

function Main() {
  const { contactRef } = useScroll(); // Use the contactRef from context

  const [isMediumScreen, setIsMediumScreen] = useState(false);
  const [isSmallDevice, setIsSmallDevice] = useState(false);

  useEffect(() => {
    // Function to check screen width and height and set state
    const handleResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;

      // Check for medium screen width (520px - 767px)
      if (width > 520 && width < 767) {
        setIsMediumScreen(true);
      } else {
        setIsMediumScreen(false);
      }

      // Check for small devices with height < 690px and width > 410px
      if (height < 690 && width > 410) {
        setIsSmallDevice(true);
      } else {
        setIsSmallDevice(false);
      }
    };

    // Call handleResize initially and add event listener
    handleResize();
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className='w-screen bg-mainBg'>
      
      <SEO
        title="ChiaDia&#174; - Healthy Drinks"
        description="Discover ChiaDia, offering healthy chia-based drinks and products. Stay refreshed and live healthy!"
        keywords={['ChiaDia', 'healthy drinks', 'chia seeds', 'refreshing drinks', 'chia products']}
        image="https://chiadia.com/images/social-share.jpg"
        url="https://chiadia.com"
      />

      <div className={`hero md:h-auto h-auto md:w-screen w-auto md:pt-[0rem] pt-[80px]`}>
        <Hero />
      </div>
      <div className='interesting h-full'>
        <FillerOne />
      </div>
      <div className='ProductsList w-full md:b-[0px] mb-[10%]'>
        <ProductsList />
      </div>

      {/* Conditionally apply padding-top based on screen width */}
      <div className={`Usp md:pt-[1%] md:pb-[10rem] pb-[%] ${isMediumScreen ? 'pt-[0vh]' : ''}  ${isSmallDevice ? 'pt-[30%]' : 'pt-[2vh]'}`}>
        <Usp />
      </div>

      <div id="contact-section" ref={contactRef}>
        <Contact />
      </div>

      <div className='Social w-full'>
        <Social />
      </div>
      <div className='FindAsAt h-full w-full md:pt-[8rem]'>
        <FindAsAt />
      </div>
      <div className='AvailableAt w-full h-auto'>
        <AvailableAt />
      </div>
    </div>
  );
}

export default Main;
