import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './styles/productDetails.css';
import productData from '../../data/productCardData.json';
import { ClipLoader } from 'react-spinners';
import NutritionalInfo from './assets/NutritionalInfo';
import ViewProducts from './assets/ViewProducts';
import IngridientsAnim from './assets/IngridientsAnim';
import BottleAnimation from './assets/BottleAnimation';

// Import product images
import limeDetox from '../../assets/allProducts/Lime Detox/Lime-Detox.png';
import strawberryElixir from '../../assets/allProducts/Strawberry Elixir/strawberry-elixir.png';
import superGinger from '../../assets/allProducts/Super Ginger/super-ginger.png';
import redCurrantPowerC from '../../assets/allProducts/Red Currant Power C/power-c.png';

// Import feature icons
import nonGmo from '../../assets/productDetails/usps under text/non-gmo.svg';
import vegan from '../../assets/productDetails/usps under text/vegan.svg';
import lowCalorie from '../../assets/productDetails/usps under text/low-calorie.svg';
import highProtein from '../../assets/productDetails/usps under text/high-protein.svg';

import sticker from '../../assets/productDetails/powered.png';
import SEO from '../../SEO';


// Define the colors array
const colors = [
  "bg-circleGreen", // "#4CAC57"
  "bg-circleRed",   // "#D63A3F"
  "bg-circleYellow", // "#E5CB56"
  "bg-circlePink"   // "#DD8480"
];

const imageMap = {
  0: limeDetox,
  1: strawberryElixir,
  2: superGinger,
  3: redCurrantPowerC,
};

const seoConfigs = {
  0: {
    title: "Lime Detox - Refresh, Detox, and Own the Dia",
    description: "Experience the refreshing Lime Detox drink that combines chia seeds and lime to detoxify and energize.",
    keywords: ["Lime Detox", "Chia Seeds", "Detox Drink", "Health", "Hydration"],
    image: limeDetox,
    url: "https://chiadia.com/products/lime-detox"
  },
  1: {
    title: "Strawberry Elixir - Glow Your Dia with Berry",
    description: "The Strawberry Elixir pairs chia seeds and strawberries for a glowing skin boost.",
    keywords: ["Strawberry Elixir", "Chia Seeds", "Glow", "Health", "Beauty"],
    image: strawberryElixir,
    url: "https://chiadia.com/products/strawberry-elixir"
  },
  2: {
    title: "Super Ginger - Ginger Up Your Dia!",
    description: "Super Ginger drink combines chia seeds and premium ginger for metabolism support and detoxification.",
    keywords: ["Super Ginger", "Chia Seeds", "Detox", "Health", "Metabolism"],
    image: superGinger,
    url: "https://chiadia.com/products/super-ginger"
  },
  3: {
    title: "Red Currant Power C - Vitamin C for Every Dia",
    description: "Red Currant Power C combines chia seeds and red currants for an immune boost.",
    keywords: ["Red Currant Power C", "Chia Seeds", "Vitamin C", "Health", "Immune Support"],
    image: redCurrantPowerC,
    url: "https://chiadia.com/products/red-currant-power-c"
  }
};

function ProductDetails() {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false); // State for toggling NutritionalInfo
  const [image, setImage] = useState(null);
  const [hoveredImage, setHoveredImage] = useState("");

  useEffect(() => {
    // Fetch product data based on the ID
    const fetchedProduct = productData.find(p => p.id === parseInt(id));
    setProduct(fetchedProduct);
    setLoading(false);
  }, [id]);

  useEffect(() => {
    // Dynamically set the product image
    const productImage = imageMap[id];
    setImage(productImage);
  }, [id]);

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  if (loading) {
    return (
      <div className="spinner-container">
        <ClipLoader color="#4CAC57" loading={loading} size={150} />
      </div>
    );
  }

  if (!product) {
    return <div>Product not found</div>;
  }

  const titleWords = product.title.split(' ');
  let processedTitle;
  if (titleWords[1] && titleWords[1].toLowerCase() === 'currant') {
    processedTitle = (
      <>
        <div>{titleWords.slice(0, 2).join(' ')}</div>
        <div>{titleWords.slice(2).join(' ')}</div>
      </>
    );
  } else {
    processedTitle = (
      <>
        <div>{titleWords[0]}</div>
        <div>{titleWords.slice(1).join(' ')}</div>
      </>
    );
  }

  const mainColorIndex = product.id % colors.length;
  const mainColorClass = colors[mainColorIndex];
  const remainingColors = colors.filter((_, index) => index !== mainColorIndex);

  const seoConfig = seoConfigs[product.id];

  return (
    <div className="pd w-screen relative md:pt-[233px] pt-[25%]">
      <SEO
        title={seoConfig.title}
        description={seoConfig.description}
        keywords={seoConfig.keywords}
        image={seoConfig.image}
        url={seoConfig.url}
      />
      <div className="pfSection flex flex-col items-center">
        <div className="topSection flex justify-between items-between w-full md:px-[161px] px-[10%] md:flex-row flex-col">
          <div className="tlSection flex flex-col gap-[20px] md:pb-[0px] md:pb-[30vh] pb-[20%]">
            <h1 className="text-blackishText md:text-[11.4vh] text-[11.4vw] leading-[90%] font-bold">
              {processedTitle}
            </h1>
            <div className='flagShape '>
              <img src={sticker} alt="Non-GMO" className="object-contain md:max-w-[45%]" />
            </div>
            <div className="circles flex justify-between pb-[0.4rem]">
              <div className="cals w-full h-[97px] flex gap-[26px] justify-start">
                <div className="h-[72px] md:w-[72px] w-[13.7vw] flex flex-col items-center justify-center relative">
                  <img src={highProtein} alt="High Protein" className="object-contain" />
                  <div className="absolute md:bottom-[-2.9rem] bottom-[-9vw] left-1/2 transform -translate-x-1/2">
                    <div className="relative inline-block w-[10rem] flex items-center justify-center">
                      <div className=" text-ogGreenText md:text-[12px] text-[2.3vw] font-bold py-2 px-[6px] rounded-full mb-1 text-center w-fit">
                        HIGH PROTEIN
                      </div>
                    </div>
                  </div>
                </div>

                <div className="h-[72px] md:w-[72px] w-[13.7vw] flex flex-col items-center justify-center relative">
                  <img src={lowCalorie} alt="Low Calorie" className="object-contain" />
                  <div className="absolute md:bottom-[-2.9rem] bottom-[-9vw] left-1/2 transform -translate-x-1/2">
                    <div className="relative inline-block w-[10rem] flex items-center justify-center">
                      <div className=" text-ogGreenText md:text-[12px] text-[2.3vw] font-bold py-2 px-[6px] rounded-full mb-1 text-center w-fit">
                        LOW CALORIE
                      </div>
                    </div>
                  </div>
                </div>

                <div className="h-[72px] md:w-[72px] w-[13.7vw] flex flex-col items-center justify-center relative">
                  <img src={vegan} alt="Vegan" className="object-contain" />
                  <div className="absolute md:bottom-[-2.9rem] bottom-[-9vw] left-1/2 transform -translate-x-1/2">
                    <div className="relative inline-block w-[10rem] flex items-center justify-center">
                      <div className=" text-ogGreenText md:text-[12px] text-[2.3vw] font-bold py-2 px-[6px] rounded-full mb-1 text-center w-fit">
                        VEGAN
                      </div>
                    </div>
                  </div>
                </div>

                <div className="h-[72px] md:w-[72px] w-[13.7vw] flex flex-col items-center justify-center relative">
                  <img src={nonGmo} alt="Non-GMO" className="object-contain" />
                  <div className="absolute md:bottom-[-2.9rem] bottom-[-9vw] left-1/2 transform -translate-x-1/2">
                    <div className="relative inline-block w-[10rem] flex items-center justify-center">
                      <div className=" text-ogGreenText md:text-[12px] text-[2.3vw] font-bold py-2 px-[6px] rounded-full mb-1 text-center w-fit">
                        NON-GMO
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div className="infoButton">
              <NutritionalInfo product={product} isOpen={isOpen} setIsOpen={setIsOpen} />
            </div>
          </div>


          <div className="trSection flex md:pb-[8rem] mb-[14%]">

            <BottleAnimation  bottle={image} mainColorClass={mainColorClass} id={product.id}/>
            {/*<div className={`bigCircle ${mainColorClass} w-[748px] h-[748px] rounded-full relative top-[-130px] content-center`}>
              <img src={image} alt={product.title} className="align-center flex" />
            </div>*/}
            </div>
        </div>


        <div className="ingridientAnim">
          <IngridientsAnim colorClass={mainColorClass} product={product} />
        </div>

        <div className="bottomSection flex flex-col w-full gap-[5vh] md:gap-[0px] md:pt-[0px] pt-[8%]">
          <div className="containerSvg md:my-[2%] ">
            <svg width="fit-content" viewBox="20 20 540 150" xmlns="http://www.w3.org/2000/svg" className="arch-text md:h-[100%] h-[50%] flex items-center justify-center">
              <defs>
                <path
                  id="fullWidthArcPath"
                  d="M 80,180 A 400,800 0 0,1 500,180"
                  fill="transparent"
                />
              </defs>
              <text fontSize="50" fontWeight="bold">
                <textPath href="#fullWidthArcPath" startOffset="50%" textAnchor="middle" fill="#232121" className="md:text-[22px] text-[8.6vw] font-black tracking-[2.2%]">
                  BUT WAIT, THERE'S MORE
                </textPath>
            </text>
            </svg>
          </div>

          <div className="w-full md:h-[342px] h-auto flex items-center justify-center md:flex-row flex-col relative md:bottom-[0px] ">
            <div className='flex md:gap-[48px] gap-[20vw] flex-col md:flex-row w-full h-full items-start justify-center'>
            {productData
              .filter(p => p.id !== product.id)
              .map((otherProduct, index) => (
                <ViewProducts
                  key={otherProduct.id}
                  product={otherProduct}
                  colorClass={remainingColors[index % remainingColors.length]}
                  imageSrc={imageMap[otherProduct.id]}
                  isOpen={isOpen}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductDetails;
