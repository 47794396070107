import React, { useState } from 'react';
import './SupplierAsset.css'; // Make sure the path is correct
import email from '../../assets/contact/email-icon-green.svg'; // Ensure the path is correct
import phone from '../../assets/contact/phone-icon-green.svg'; // Ensure the path is correct

function SupplierAssetMobile() {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div 
            className={`w-full h-[100%] flex  transition-all duration-700 w-full`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div className='supplierAssetMobile flex w-full items-center align-start justify-start rounded-[2vw] flex-col pt-[6%] gap-[10%]'>
                <div className='shownSection '>
                    <p className="vertical-textMobile text-[4.6vw] leading-[64%]">
                        GET IN TOUCH<br />
                        <span className="text-[3.6vw]">
                            WITH OUR TEAM
                        </span>
                    </p>
                </div>
                <div className='hiddenArea flex flex-col relative space-y-[1.5vh] w-full'>
                    <div className='flex flex-col items-center justify-center space-y-[2%]'>
                        <div className='w-[6.2vw] h-[6.2vw] rounded-full bg-white flex items-center justify-center'>
                            <img
                                src={email}
                                alt="Email Icon"
                                className="object-contain h-[3vw]"
                            />
                        </div>
                        <h4 className='text-newWhiteishText text-left text-[2.8vw] flex items-center justify-start pt-[0.2vh]'>
                            qinado@gmail.com
                        </h4>
                    </div>

                    <div className='flex flex-col items-center justify-center'>
                        <div className='w-[6.2vw] h-[6.2vw] rounded-full bg-white flex items-center justify-center space-y-[2%]'>
                            <img
                                src={phone}
                                alt="Phone Icon"
                                className="object-contain h-[2.8vw]"
                            />
                        </div>
                        <h4 className='text-newWhiteishText text-left text-[2.8vw] flex items-center justify-start pt-[0.2vh]'>
                            +39870210192
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SupplierAssetMobile;
