import React, { useEffect, useRef, useState } from 'react';
import bottle from '../../assets/fillers/bottle1.png';

function FillerOne() {
  const [isInView, setIsInView] = useState(false);
  const bottleRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        console.log("Is in view:", entry.isIntersecting);
        setIsInView(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    if (bottleRef.current) {
      observer.observe(bottleRef.current);
    }

    return () => {
      if (bottleRef.current) {
        observer.unobserve(bottleRef.current);
      }
    };
  }, []);

  return (
    <div className="int w-screen h-full py-[4.2%] px-[9vw]">
      <div className="nutr flex flex-col">
        <div className="topSection flex">
          <div className="tLeftSection w-auto">
            <h1 className="text-[7vw] text-blackishText font-black whitespace-nowrap">
              Bye Sugar,
            </h1>
          </div>
          <div className="tRightSection w flex items-center overflow-visible">
            <img
              ref={bottleRef}
              src={bottle}
              alt="bottle"
              className={`relative top-[10%] transition-transform duration-1000 ease-in-out z-[0] w-[50rem] ${
                isInView ? 'translate-x-0' : 'translate-x-full'
              }`}
            />
          </div>
        </div>

        <div className="bottomSection relative flex flex-col z-1">
          <div>
            <h1 className="text-[7vw] font-black leading-[100%]">
              <span className="pr-[2vw] text-blackishText">
                Hellooo
              </span>
              <span className="text-ogGreenText">
                Nutrition.
              </span>
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FillerOne;
