import React, { useState, useEffect } from 'react';

// Import the images
import acacia from '../../../assets/hero/acacica.png'; 

// Ginger images
import ginger1 from '../../../assets/animImg/Super Ginger/Product Section Animation/ginger up left.png';
import ginger2 from '../../../assets/animImg/Super Ginger/Product Section Animation/ginger-lemon up right.png';
import ginger3 from '../../../assets/animImg/Super Ginger/Product Section Animation/lemon down left.png';
import ginger4 from '../../../assets/animImg/Super Ginger/Product Section Animation/ginger down right.png';

// Lime images
import lime1 from '../../../assets/animImg/Lime Detox/Product Section Animation/lime 2 up left.png';
import lime2 from '../../../assets/animImg/Lime Detox/Product Section Animation/lime 1 up right.png';
import lime3 from '../../../assets/animImg/Lime Detox/Product Section Animation/limon 2 down left.png';
import lime4 from '../../../assets/animImg/Lime Detox/Product Section Animation/limon 1 down right.png';

// Strawberry images
import strawberry1 from '../../../assets/animImg/Strawberry Elixir/Product Section Animation/strawberry up left.png';
import strawberry2 from '../../../assets/animImg/Lime Detox/Product Section Animation/limon 2 down left.png';
import strawberry3 from '../../../assets/animImg/Strawberry Elixir/Product Section Animation/strawberry down left.png';
import strawberry4 from '../../../assets/animImg/Strawberry Elixir/Product Section Animation/strawberry down right.png';

// Currant images
import currant1 from '../../../assets/animImg/Red Currant Power C/Product Section Animation/leaf up left.png';
import currant2 from '../../../assets/animImg/Red Currant Power C/Product Section Animation/leaf up right.png';
import currant3 from '../../../assets/animImg/Red Currant Power C/Product Section Animation/red currant down left.png';
import currant4 from '../../../assets/animImg/Red Currant Power C/Product Section Animation/red currant down right.png';

function BottleAnimation({ bottle, mainColorClass, id }) {
  const [isScrolled, setIsScrolled] = useState(false);

  // Handle scroll detection
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsScrolled(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Preload all images to avoid loading delays or wrong images
  useEffect(() => {
    const preloadImages = (imageArray) => {
      imageArray.forEach((imageSrc) => {
        const img = new Image();
        img.src = imageSrc;
      });
    };

    preloadImages([
      acacia,
      ginger1, ginger2, ginger3, ginger4,
      lime1, lime2, lime3, lime4,
      strawberry1, strawberry2, strawberry3, strawberry4,
      currant1, currant2, currant3, currant4
    ]);
  }, []);

  // Map images based on the id
  const getImageSet = () => {
    switch (id) {
      case 0:
        return { img1: lime1, img2: lime2, img3: lime3, img4: lime4 };
      case 1:
        return { img1: strawberry1, img2: strawberry2, img3: strawberry3, img4: strawberry4 };
      case 2:
        return { img1: ginger1, img2: ginger2, img3: ginger3, img4: ginger4 };
      case 3:
        return { img1: currant1, img2: currant2, img3: currant3, img4: currant4 };
      default:
        return { img1: ginger1, img2: ginger2, img3: ginger3, img4: ginger4 }; // Default to ginger if no match
    }
  };

  const { img1, img2, img3, img4 } = getImageSet();

  return (
    <div className='w-full h-full'>
      <div className='heroContainer relative w-full'>
        <div className={`bigCircle ${mainColorClass} md:w-[598px] md:h-[598px] w-[268px] h-[268px] rounded-full relative top-[-px] md:left-[0px] left-[9vw]`}>
          <img src={bottle} alt="Bottle" className="align-center flex z-10 relative md:left-auto left-[30%] md:mt-auto mt-[15%]" />
        </div>
        <div className={`stuff w-full h-full flex items-center justify-center ${isScrolled ? 'animate' : ''}`}>
          
          {/* Conditionally render Acacia image based on the id */}
          <img
            src={acacia}
            alt="Acacia"
            className={`acacia object-contain absolute md:bottom-[4rem] bottom-[2rem] left-[-3.6%] ${id === 0 ? 'md:min-w-[84vh] min-w-[45vh] w-[45vh]' : 'min-w-[0%] w-[0px]'}`}
          />

          <img
            src={img1}
            alt="Leaf 1"
            className={`object-contain absolute md:w-[13.6rem] w-[23vw] md:top-[-3.2rem] top-[-3.4vw] md:left-[-3.6rem] left-[-3vw] ${isScrolled ? '' : 'start-center2'}`}
          />
          <img
            src={img2}
            alt="Leaf 2"
            className={`object-contain absolute md:w-[12.8rem] w-[23vw] md:top-[-0rem] top-[1vw] md:right-[-4rem] right-[-8vw] ${isScrolled ? '' : 'start-center'}`}
          />
          <img
            src={img3}
            alt="Leaf 3"
            className={`object-contain absolute md:left-[-3.2rem] left-[-8vw] w-[23vw] md:bottom-[12rem] bottom-[18vw] md:w-[12.8rem] ${isScrolled ? '' : 'start-center2'}`}
          />
          <img
            src={img4}
            alt="Leaf 4"
            className={`object-contain absolute md:w-[13.6rem] w-[23vw] md:bottom-[13.6rem] bottom-[20vw] md:right-[-2.4rem] right-[-7vw] ${isScrolled ? '' : 'start-center3'}`}
          />
        </div>
      </div>
    </div>
  );
}

export default BottleAnimation;
