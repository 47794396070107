import React from 'react';
import './styles/productCard.css';
import leaf from '../../assets/productSection/leaf.svg';

function CardOpen({ product }) {
  const { nutritionalValues, title, ingredients } = product;

  return (
    <div className='card-open w-auto h-[685px] bg-cardRedBg rounded-[36px] flex flex-col justify-center space-y-[22px] px-[39px]'>
      <div className='cals w-[full] h-[97px] flex space-x-[21px] justify-center'>
        <div className='border-[4px] border-whiteishBorder h-full w-[95px] rounded-full flex flex-col items-center justify-center text-white'>
          <h1 className='font-bold text-[36px]'>{nutritionalValues.sugar}</h1>
          <h4 className='font-medium text-[15px] relative top-[-10%] capitalize'>Sugar</h4>
        </div>
        <div className='border-[4px] border-whiteishBorder h-full w-[95px] rounded-full flex flex-col items-center justify-center text-white'>
          <h1 className='font-bold text-[36px]'>{nutritionalValues.calories}</h1>
          <h4 className='font-medium text-[15px] relative top-[-10%] capitalize'>Calories</h4>
        </div>
        <div className='border-[4px] border-whiteishBorder h-full w-[95px] rounded-full flex flex-col items-center justify-center text-white'>
          <h1 className='font-bold text-[36px]'>{nutritionalValues.protein}</h1>
          <h4 className='font-medium text-[15px] relative top-[-10%] capitalize'>Protein</h4>
        </div>
        <div className='border-[4px] border-whiteishBorder h-full w-[95px] rounded-full flex flex-col items-center justify-center bg-menuBg '>
          <div className='flex flex-col items-center'>
            <img
            src={leaf}
            alt="Leaf"
            className=""
          />
            <h4 className='font-medium text-[15px] text-cardRedText relative top-[-3%] capitalize'>Organic</h4>
          </div>
        
        </div>
      </div>
      
      <div className='bann w-full h-[66px]'>
        <div className='w-full h-full bg-menuBg rounded-[28px] flex justify-center items-center'>
          <h2 className='text-cardRedText font-bold text-[39px]'>{title}</h2>
        </div>
      </div>
      <div className='ingr h-[177px] w-full flex flex-col'>
        <h2 className='font-bold text-[28px] text-white'>Ingredients:</h2>
        <ul className='ingredient-list list-none p-0 m-0'>
          <li className='ingredient-item font-medium text-[22px] text-white'>{ingredients.ing1}</li>
          <li className='ingredient-item font-medium text-[22px] text-white'>{ingredients.ing2}</li>
          <li className='ingredient-item font-medium text-[22px] text-white'>{ingredients.ing3}</li>
          <li className='ingredient-item font-medium text-[22px] text-white'>{ingredients.ing4}</li>
        </ul>
      </div>
    </div>
  );
}

export default CardOpen;
