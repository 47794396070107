import React from 'react';
import './styles/findAsAt.css';

import loc1 from '../../assets/sliderr/350 locations.png';
import loc2 from '../../assets/sliderr/granap.png';
import loc3 from '../../assets/sliderr/reptil.png';
import loc4 from '../../assets/sliderr/tinex-green.png';
import loc5 from '../../assets/sliderr/top-market.png';

function FindAsAt() {
  const images = [loc1, loc2, loc3, loc4, loc5];

  return (
    <div className="w-screen md:h-[211px] bg-white flex items-center justify-center overflow-hidden">
      <div className="staticSlider flex items-center justify-center space-x-[8%] px-[10%]">
        {/* Text Element */}
        <h2 className="md:text-[2.8vw] text-[13.4px] text-bold text-ogGreenText font-bold whitespace-nowrap tracking-[-2px]">
          Find Us At
        </h2>
        
        {/* Images */}
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Location ${index + 1}`}
            className="dynamic-image"
            style={{
              width: 'auto', // Maintain aspect ratio
              maxWidth: '10vw', // Max width for larger screens
              height: 'auto', // Maintain aspect ratio
            }}
          />
        ))}
      </div>
    </div>
  );
}

export default FindAsAt;
