import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BlogCard from './BlogCard';
import blogsData from '../../data/blogData.json'; // Adjust the path as necessary
import BlogTimeAsset from './BlogTimeAsset';
import { FaArrowRight } from "react-icons/fa";
import bp from '../../assets/blog/blog1.jpg'; // Default image
import arrowImage from '../../assets/productSection/rArrGreen.svg';
import SEO from '../../SEO'; 

const blogImages = {
  1: require('../../assets/blog/BLOGS PHOTOS/1blog/1.png'),
  2: require('../../assets/blog/BLOGS PHOTOS/2blog/2.png'),
  3: require('../../assets/blog/BLOGS PHOTOS/3blog/3.png'),
  4: require('../../assets/blog/BLOGS PHOTOS/4blog/4.png'),
  5: require('../../assets/blog/BLOGS PHOTOS/5blog/5.png'),
};

function BlogContainer() {
  const [blogs, setBlogs] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();

  useEffect(() => {
    // Load blog data
    setBlogs(blogsData);
  }, []);

  useEffect(() => {
    // Handle window resize
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    
    // Cleanup event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleReadMore = (id) => {
    navigate(`/blog_details/${id}`);
  };

  if (!blogs.length) {
    return <div>Loading...</div>;
  }

  // Sort blogs by ID in descending order
  const sortedBlogs = [...blogs].sort((a, b) => b.id - a.id);
  
  // Get the blog with the highest ID
  const topBlog = blogs[3];

  // Dynamically get the blog image or fallback to default
  const blogImage = blogImages[topBlog.id] || bp;

  const filteredBlogs = sortedBlogs.filter(blog => blog.id !== topBlog.id);
  const newBlogsArray = [topBlog, ...filteredBlogs];

  return (
    <div className="bcont w-screen h-auto px-[9vw] md:pt-[18vh] pt-[11vh]">
      <SEO
        title="ChiaDia&#174; Blog - Insights on Health & Sustainability"
        description="Explore the ChiaDia Blog for the latest insights on health, sustainability, and chia-based products."
        keywords={['ChiaDia', 'Blog', 'Health', 'Sustainability', 'Chia Seeds', 'Chia-Based Products']}
        image="https://chiadia.com/images/default-blog.jpg"
        url="https://chiadia.com/blog"
      />
      <div className="blogContCont w-full flex flex-col">
        {/* Top section for the blog with the highest ID */}
        <div className="topSection md:h-[61vh] w-full md:flex-row flex-col md:pb-[2vh] pb-[5vh] md:flex hidden">
          <div className="leftSection md:w-[30vw] min-w-[30vw] object-fit">
            <img
              src={blogImage}
              alt="cardItem"
              className="h-full w-full md:rounded-l-[2vw] md:rounded-tl-[2vw] rounded-tl-[2vw] rounded-rt-[2vw] md:rounded-rt-[0vw] object-cover"
            />
          </div>
          <div className="rightSection pl-[3vw] h-auto bg-ogGreenBg w-auto rounded-r-[2vw] md:rounded-l-[0vw] rounded-l-[2vw] flex flex-col justify-between">
            <div className="flex justify-end w-full p-[2vh]">
              <BlogTimeAsset isCard={false} time={Math.ceil(topBlog.paragraphs.reduce((acc, para) => acc + para.paragraph.split(' ').length, 0) / 200)} />
            </div>
            <div className="flex flex-col space-y-[2vh] pb-[10vh] pr-[10vw]">
              <h1 className="text-newWhiteishText text-[3.9vw] font-bold leading-[95%] pb-[2%]">
                {topBlog.title}
              </h1>
              <button
                className="md:h-[5.7vh] md:w-[246px] rounded-[50vw] bg-whiteishBg transition-opacity duration-300 opacity-100 whitespace-nowrap"
                onClick={() => handleReadMore(topBlog.id)}
              >
                <h4 className="text-ogGreenText md:text-[1.5rem] text-[5vw] font-bold w-full flex items-center justify-between px-[1vw]">
                  Read More <img src={arrowImage} alt="arrow" className="ml-2 w-[2vw] h-[3.4vh]" />
                </h4>
              </button>
            </div>
          </div>
        </div>

        {/* Bottom section for all blog cards */}
        <div className="bottomSection flex flex-wrap justify-center md:gap-[3vw] gap-[9vw] mt-[4vh]">
          {windowWidth < 768
            ? newBlogsArray.map((blog) => (
                <BlogCard
                  key={blog.id}
                  blog={blog}
                  isCard={true}
                  onReadMore={() => handleReadMore(blog.id)}
                />
              ))
            : filteredBlogs.map((blog) => (
                <BlogCard
                  key={blog.id}
                  blog={blog}
                  isCard={true}
                  onReadMore={() => handleReadMore(blog.id)}
                />
              ))}
        </div>
      </div>
    </div>
  );
}

export default BlogContainer;
