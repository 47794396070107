import React, { useState, useEffect } from 'react';
import BlogCard from './BlogCard';

function BlogSlider({ blogs, onReadMore, currentBlogId }) {
  const [currentPage, setCurrentPage] = useState(0);
  const [blogsPerPage, setBlogsPerPage] = useState(3);

  // Randomize and filter blogs, excluding the current one
  const shuffledBlogs = [...blogs].sort(() => 0.5 - Math.random());
  const filteredBlogs = shuffledBlogs.filter(blog => blog.id !== currentBlogId);

  const totalPages = Math.ceil(filteredBlogs.length / blogsPerPage);

  // Adjust the number of blogs per page based on screen width
  useEffect(() => {
    const updateBlogsPerPage = () => {
      if (window.innerWidth <= 768) {
        setBlogsPerPage(1); // Display 1 blog on smaller screens
      } else if (window.innerWidth <= 1024) {
        setBlogsPerPage(2); // Display 2 blogs on medium screens
      } else {
        setBlogsPerPage(3); // Display 3 blogs on larger screens
      }
    };

    updateBlogsPerPage(); // Initial check
    window.addEventListener('resize', updateBlogsPerPage);
    return () => window.removeEventListener('resize', updateBlogsPerPage);
  }, []);

  // Determine the blogs to display on the current page
  const startIndex = currentPage * blogsPerPage;
  const currentBlogs = filteredBlogs.slice(startIndex, startIndex + blogsPerPage);

  // Handle page change
  const handlePageChange = (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  return (
    <div className="bsd w-auto h-full">
      <div className="blogSlider flex flex-col">
      <hr
        className="w-full h-[7px] bg-[#4CAC57] opacity-100"
      />
        <div className="titleSec flex items-center justify-start pb-[27px] md:pt-[1rem] pt-[1.7rem]">
          <h2 className="text-blackishText md:text-[64px] text-[10vw] leading-[100%] font-bold">
            Related Articles
          </h2>
        </div>

        {/* Display the blog cards */}
        <div className="blogsSlider flex space-x-[58px]">
          {currentBlogs.map((blog) => (
            <BlogCard
              key={blog.id}
              blog={blog}
              isCard={true}
              onReadMore={() => onReadMore(blog.id)}
            />
          ))}
        </div>

        {/* Pagination buttons */}
        <div className="buttons w-auto flex items-center justify-center space-x-2 mt-[54px]">
          <div className="flex w-fit items-center justify-center space-x-[40px]">
            {Array.from({ length: totalPages }).map((_, index) => (
              <div
                key={index}
                className={`w-[37px] h-[37px] border-[4px] flex items-center justify-center border-ogGreenBorder rounded-full`}
                onClick={() => handlePageChange(index)}
              >
                <button
                  className={`w-[18px] h-[18px] rounded-full ${
                    currentPage === index ? 'bg-ogGreenBg' : 'bg-mainBg'
                  }`}
                ></button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogSlider;
