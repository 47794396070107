import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import BlogSlider from './BlogSlider';
import blogsData from '../../data/blogData.json'; // Adjust the path as necessary
import bp from '../../assets/blog/blog1.jpg'; // Default image
import BlogTimeAsset from './BlogTimeAsset';
import BlogCard from './BlogCard';

import arrowImage from '../../assets/productSection/rArrGreen.svg';
import SEO from '../../SEO'; 

// Image map to associate each blog ID with its image URL
const blogImages = {
  1: require('../../assets/blog/BLOGS PHOTOS/1blog/1.png'),
  2: require('../../assets/blog/BLOGS PHOTOS/2blog/2.png'),
  3: require('../../assets/blog/BLOGS PHOTOS//3blog/3.png'),
  4: require('../../assets/blog/BLOGS PHOTOS/4blog/4.png'),
  5: require('../../assets/blog/BLOGS PHOTOS/5blog/5.png'),
};

function BlogDetails() {
  const { id } = useParams(); // Extract blog ID from URL
  const [currentBlog, setCurrentBlog] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Scroll to top when the URL changes
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    // Find the blog with the matching ID
    const blog = blogsData.find(blog => blog.id === parseInt(id, 10));
    if (blog) {
      setCurrentBlog(blog);
    } else {
      setError('Blog not found');
    }
  }, [id]);

  const handleReadMore = (id) => {
    navigate(`/blog_details/${id}`);
  };

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (!currentBlog) {
    return <div className="loading">Loading...</div>; // Show a loading state while fetching the current blog
  }

  // Dynamically get the blog image or fallback to default
  let blogImage;
  try {
    blogImage = blogImages[currentBlog.id];
  } catch (err) {
    blogImage = bp;
  }

  const seoConfigs = {
    1: {
      title: "ChiaDia at Gulfood Dubai 2024 - Premium Drinks Introduction",
      description: "Discover how ChiaDia showcased its premium drinks at Gulfood Dubai 2024, engaging with a health-conscious market.",
      keywords: ["ChiaDia", "Gulfood Dubai", "Health Drinks", "Middle East", "Premium Beverages"],
      image: "https://chiadia.com/images/gulf-food-dubai.jpg",
      url: "https://chiadia.com/blog/gulfood-dubai-2024"
    },
    2: {
      title: "ChiaDia at Nordic Organic Food Fair - A Market Debut",
      description: "Join ChiaDia's journey at the Nordic Organic Food Fair, highlighting our impact on the Nordic health market.",
      keywords: ["ChiaDia", "Nordic Organic Food Fair", "Health Market", "Organic Products", "Sweden"],
      image: "https://chiadia.com/images/nordic-organic-fair.jpg",
      url: "https://chiadia.com/blog/nordic-organic-food-fair"
    },
    3: {
      title: "The Power of Chia Seeds - The Superfood Revolution",
      description: "Explore the health benefits of chia seeds, hailed as the superfood with rich historical significance.",
      keywords: ["Chia Seeds", "Superfood", "Health Benefits", "Nutrition", "Wellness"],
      image: "https://chiadia.com/images/chia-seeds-superfood.jpg",
      url: "https://chiadia.com/blog/chia-seeds-superfood"
    },
    4: {
      title: "The Founding Story of ChiaDia - Two Sisters, One Vision",
      description: "Discover the inspiring journey of ChiaDia, from inspiration to innovation led by two sisters.",
      keywords: ["ChiaDia", "Founding Story", "Sisters", "Entrepreneurship", "Health Drinks"],
      image: "https://chiadia.com/images/founding-story.jpg",
      url: "https://chiadia.com/blog/founding-story"
    },
    5: {
      title: "The Rise of Functional Drinks - Shaping the Beverage Industry",
      description: "Learn about the shift in beverage preferences towards functional drinks and how ChiaDia fits into this trend.",
      keywords: ["Functional Drinks", "Health Trends", "Beverage Industry", "ChiaDia", "Wellness"],
      image: "https://chiadia.com/images/functional-drinks-rise.jpg",
      url: "https://chiadia.com/blog/rise-of-functional-drinks"
    }
  };

  const seoConfig = seoConfigs[currentBlog.id];

  return (
    <div className="bcont w-screen h-auto px-[9vw] pt-[18vh]">
      <SEO
        title={seoConfig.title}
        description={seoConfig.description}
        keywords={Array.isArray(seoConfig.keywords) ? seoConfig.keywords : []}
        image={seoConfig.image}
        url={seoConfig.url}
      />
      
      <div className='blogContCont w-full flex flex-col'>
        {/* Top section for the current blog */}
        <div className='topSection hidden md:flex h-[60vh] w-full pb-[2vh]'>
          <div className='leftSection w-[30vw] min-w-[30vw]'>
            <img
              src={blogImage || bp} // Use the specific image or fallback to default
              alt={currentBlog.title}
              className="h-full w-full rounded-l-[2vw] object-cover"
            />
          </div>
          <div className='rightSection pl-[3vw] h-auto bg-ogGreenBg w-auto rounded-r-[2vw] flex flex-col justify-between'>
            <div className='flex justify-end w-full p-[2vh]'>
              <BlogTimeAsset
                isCard={false}
                time={Math.ceil(
                  (currentBlog.paragraphs.reduce((acc, para) => acc + (para.paragraph ? para.paragraph.split(' ').length : 0), 0)) / 200
                )}
              />
            </div>
            <div className='flex flex-col items-center justify-center pb-[5vh] h-full space-y-[2vh] pr-[10vw]'>
              <h1 className='text-newWhiteishText text-[3.9vw] font-bold leading-[95%]'>
                {currentBlog.title}
              </h1>
            </div>
          </div>
        </div>

        {/* Mobile view */}
        <div className='md:hidden flex'>
          <BlogCard
            key={currentBlog.id}
            blog={currentBlog}
            isCard={false}
            onReadMore={() => handleReadMore(currentBlog.id)}
          />
        </div>

        {/* Middle section for all paragraphs */}
        <div className='middleSection w-full flex flex-col py-[4vh]'>
          {currentBlog.paragraphs && currentBlog.paragraphs.map((para, index) => (
            <div key={index} className='mb-[3vh]'>
              {/* Render paragraph title if it exists */}
              {para.paragraphTitle && (
                <h2 className='text-ogGreenText md:text-[3.5vw] text-[8vw] font-bold mb-[2vh]'>
                  {para.paragraphTitle}
                </h2>
              )}

              {/* Render paragraph content */}
              {para.paragraph && (
                <p className='text-blackishText md:text-[1.5rem] text-[4vw] font-[400] leading-[128%]'>
                  {para.paragraph.split('<br>').map((line, i) => (
                    <React.Fragment key={i}>
                      {i > 0 && <><br /><br /></>} {/* Add two <br /> elements */}
                      {line.includes("Here’s a cool fact about ChiaDia:") ? (
                        <strong>{line}</strong>
                      ) : (
                        line
                      )}
                    </React.Fragment>
                  ))}
                </p>
              )}

              {/* Render benefits for blog ID 3 */}
              {currentBlog.id === 3 && para.benefits && para.benefits.length > 0 && (
                <ul className='list-disc pl-5'>
                  {para.benefits.map((benefit, i) => (
                    <li key={i} className='text-blackishText md:text-[1.5rem] text-[4vw] font-[400] leading-[128%]'>
                      {benefit.title && <strong>{benefit.title}:</strong>} {benefit.description}
                    </li>
                  ))}
                </ul>
              )}

              {/* Render benefits for blog ID 5 */}
              {currentBlog.id === 5 && para.benefits && para.benefits.length > 0 && (
                <ol className='list-decimal pl-5'>
                  {para.benefits.map((benefit, i) => (
                    <li key={i} className='text-blackishText md:text-[1.5rem] text-[4vw] font-[400] leading-[128%]'>
                      {benefit.description}
                    </li>
                  ))}
                </ol>
              )}
            </div>
          ))}
        </div>

        {/* Bottom section for the BlogSlider */}
        <div className='bottomSection max-w-screen'>
          <BlogSlider
            blogs={blogsData}
            onReadMore={handleReadMore}
            currentBlogId={currentBlog.id}
          />
        </div>
      </div>
    </div>
  );
}

export default BlogDetails;
