import React from 'react';
import './styles/productCard.css';
import bottleG from '../../assets/productSection/bottleG.png'; 
import cardPng from '../../assets/productSection/sokce-product.png'; 

function CardClosed() {
  return (
    <div className='card-closed w-auto h-[685px] bg-cardRedBg rounded-[36px] flex flex-col justify-center items-center'>
      <img
        src={cardPng}
        alt="Background"
        className=""
      />
    </div>
  );
}

export default CardClosed;
