import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import Navbar from './components/navbar/Navbar';
import Mobilenavbar from './components/navbar/MobileNavbar';
import ProductSection from './components/products/ProductSection';
import ProductCard from './components/products/ProductCard';
import ProductDetails from './components/products/ProductDetails';
import Main from './components/Main';
import Development from './components/Development';
import ProductMain from './components/products/ProductMain';
import ProductsList from './components/products/ProductsList';
import BlogContainer from './components/blog/BlogContainer';
import BlogDetails from './components/blog/BlogDetails';
import Faq from './components/contact/Faq';
import OurStory from './components/OurStory/OurStory';
import WhyChiadia from './components/WhyChiadia/WhyChiadia';
import Footer from './components/contact/Footer';
import './App.css';

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="App w-[100%] h-full bg-mainBg">
      <div className="container w-screen h-full">
        <SecretRoutes isMobile={isMobile} />
      </div>
    </div>
  );
}

function SecretRoutes({ isMobile }) {
  const location = useLocation();
  
  return (
    <>
      {isMobile ? <Mobilenavbar /> : <Navbar />}
      <Routes>
        {/* Define your routes here */}
        <Route path="/*" element={<Main />} />
        <Route path="/products" element={<ProductSection />} />
        <Route path="/product" element={<ProductCard />} />
        <Route path="/product_details/:id" element={<ProductDetails />} />
        <Route path="/product_main" element={<ProductMain />} />
        <Route path="/product_list" element={<ProductsList />} />
        <Route path="/blog" element={<BlogContainer />} />
        <Route path="/blog_details/:id" element={<BlogDetails />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/OurStory" element={<OurStory />} />
        <Route path="/WhyChiaDia" element={<WhyChiadia />} />

        {/* Redirect any unmatched routes to the Main component */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
