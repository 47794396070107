import React from 'react';
import { FaTools } from 'react-icons/fa'; // Import an icon from react-icons

function Development() {
  return (
    <div className='w-screen h-screen flex items-center justify-center bg-mainBg'>
      <div className='text-center flex justify-center items-center flex-col'>
        <FaTools size={100} className='text-gray-500 mb-4' />
        <h1 className='text-2xl font-bold mb-2'>Site Under Maintenance</h1>
        <p className='text-gray-700'>We're working hard to get things back up and running. Please check back later.</p>
      </div>
    </div>
  );
}

export default Development;
