import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import '../styles/productAssets.css';

import bran from '../../../assets/ingridientSec/bran-ingridients.svg';
import sise from '../../../assets/ingridientSec/sise-ingridients.svg';

// Import the image as usual
import siseGif from '../../../assets/ingridientSec/siseAnim.gif';

function IngridientsAnim({ colorClass, product }) {
  const { ref, inView } = useInView({
    triggerOnce: true, // Change this to false if you want to re-trigger the animation when the component re-enters view
    threshold: 1.0, // Ensures the animation triggers only when the entire component is in view
  });

  const [topPosition, setTopPosition] = useState('46vh'); // Converted to vh
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [preloadedGif, setPreloadedGif] = useState(null); // State to hold preloaded gif

  // Preload image and store in session storage
  useEffect(() => {
    const preloadImage = () => {
      const storedGif = sessionStorage.getItem('siseGif');

      if (storedGif) {
        // If the gif is already in session storage, use it
        setPreloadedGif(storedGif);
      } else {
        // If not, preload it and save it in session storage
        const img = new Image();
        img.src = siseGif;
        img.onload = () => {
          sessionStorage.setItem('siseGif', siseGif);
          setPreloadedGif(siseGif);
        };
      }
    };

    preloadImage();
  }, []);

  // Update the mobile state on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (inView) {
      setTopPosition('7vh'); // Adjusted based on view
    }
  }, [inView]);

  const ingredients = product?.ingredients ? Object.values(product.ingredients) : [];

  return (
    <div className={`Ingridient w-screen h-auto pt-[5vh] pb-[5vh]  ${colorClass}`}>
      <div className='fullIng md:h-[34vh] gap-[5vh] flex px-[11.35vw] md:flex-row flex-col'>
        <div className='leftSection  flex items-center md:justify-start justify-center'>
          <h1 className='font-bold text-newWhiteishText md:text-[5.8vw] text-[12vw] leading-[76%]'>
            made using <br />
            <span className='w-max flex'>
              super 
              <hr 
                className='line' 
                style={{
                  width: isMobile ? '33vw' : '15.78vw',  // Longer on mobile
                  height: isMobile ? '0.6vh' : '1.7vh',   // Shorter height on mobile
                  background: '#F8F7F0', 
                  opacity: 1,
                  border: 'none',     // Ensure no default border from the hr tag
                  marginTop: isMobile ? '8%' : '8%', // Adjust spacing for mobile
                  marginLeft: '5%',
                }} 
              />
            </span>
            ingredients
          </h1>
        </div>
        <div className='rightSection flex-1 flex justify-center items-center'>
          <div className='leftSec relative overflow-'>
            <div 
              className='flex md:w-[8.5vw] w-[21vw] h-auto bg-contain bg-center bg-no-repeat items-center justify-center'
            >
              {/* Replace the static image with the preloaded GIF */}
              {preloadedGif && (
                <img 
                  src={preloadedGif} 
                  alt="sise animation" 
                  className='rounded-[2.08vw] md:h-[100%] h-[40vw] z-[3] max-h-[40vw] md:max-h-[100%]'
                  
                  style={{
                    top: topPosition, // Animated top position
                    objectFit: 'cover', // Maintain the aspect ratio of the GIF
                  }}
                />
              )}
            </div>
          </div>
          <div className='rightSec flex flex-col pl-[1.12vw] justify-center space-y-[1.62vh] relative top-[1%] flex-nowrap'>
            {ingredients.length > 0 ? (
              ingredients.map((ingredient, index) => {
                const isNotFirst = index > 0;
                const isNotLast = index < ingredients.length - 1;
                const paddingLeft = isNotFirst && isNotLast ? 'pl-[0.8vw]' : '';

                const animationDelay = `${(ingredients.length - 0.25 - index) * 0.20}s`; // Staggering based on index from bottom up

                return (
                  <div
                    key={index}
                    className={`flex flex-col ${inView ? 'animate' : ''} ${paddingLeft}`}
                    style={{ animationDelay: inView ? animationDelay : '0s' }}
                  >
                    <div 
                      className='dot-wrapper'
                      style={{ animationDelay: inView ? animationDelay : '0s' }}
                    >
                      <div className='dots'></div>
                      <h5 
                        className={`inside font-bold md:text-[1.6vw] text-[3vw] text-newWhiteishText ${paddingLeft}`}
                        style={{ animationDelay: inView ? `calc(${animationDelay} + 0.1s)` : '0s' }}
                      >
                        <span> . . . . . . . . . . . . . .</span>&nbsp; &nbsp; {ingredient}
                      </h5>
                    </div>
                  </div>
                );
              })
            ) : (
              <h5 className='inside font-bold text-[1.87vw] text-newWhiteishText'>
                No ingredients available
              </h5>
            )}
          </div>
        </div>
      </div>
      <div className='absolute w-[1px] h-[1px]' ref={ref}>

      </div>
    </div>
  );
}

export default IngridientsAnim;
