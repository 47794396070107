import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import productData from '../../data/productCardData.json';
import { ClipLoader } from 'react-spinners';
import { FaArrowRight } from "react-icons/fa";
import BottleInCircle from './assets/BottleInCircle';

// Import images
import limeDetox from '../../assets/allProducts/Lime Detox/Lime-Detox.png';
import strawberryElixir from '../../assets/allProducts/Strawberry Elixir/strawberry-elixir.png';
import superGinger from '../../assets/allProducts/Super Ginger/super-ginger.png';
import redCurrantPowerC from '../../assets/allProducts/Red Currant Power C/power-c.png';

import arrowImage0 from '../../assets/productSection/rArrGreen.svg';
import arrowImage1 from '../../assets/productSection/rArrRed.svg';
import arrowImage2 from '../../assets/productSection/rArrRedYellow.svg';
import arrowImage3 from '../../assets/productSection/rArrPink.svg';


function ProductMain({ id, bgColor }) {
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true); 
  const navigate = useNavigate();

  useEffect(() => {
    const fetchedProduct = productData.find(p => p.id === parseInt(id));
    setProduct(fetchedProduct);
    setLoading(false); 
  }, [id]);

  if (loading) {
    return (
      <div className="spinner-container">
        <ClipLoader color="#4CAC57" loading={loading} size={150} />
      </div>
    );
  }
  
  if (!product) {
    return <div>Product not found</div>;
  }

  const handleReadMore = () => {
    navigate(`/product_details/${product.id}`);
  };

  // Words that should not be bold
  const normalWords = ["with", "and", "for"];

  // Split subtitle into words and apply conditional styling
  const styledSubtitle = product.subtitle.split(' ').map((word, index) => {
    const isNormalWord = normalWords.includes(word.toLowerCase());
    const isWowWord = word.toLowerCase() === "wow"; // Check if the word is "wow"
    return (
      <span 
        key={index} 
        className={isNormalWord ? 'font-normal' : isWowWord ? 'font-normal italic' : 'font-bold'}>
        {word}{" "}
      </span>
    );
  });

  // Mapping of IDs to text color classes
  const textColorClasses = {
    0: "text-ogGreenText",
    1: "text-ogRedText",
    2: "text-ogYellowText",
    3: "text-ogPinkText",
  };

  // Mapping of IDs to image sources
  const imageMap = {
    0: limeDetox,
    1: strawberryElixir,
    2: superGinger,
    3: redCurrantPowerC,
  };

  const arrowImageMap = {
    0: arrowImage0,
    1: arrowImage1,
    2: arrowImage2,
    3: arrowImage3,
  };

  const arrowImage = arrowImageMap[product.id];


  // Determine the text color class and image based on the product's id
  const textColorClass = textColorClasses[product.id] || "text-otherWhiteishText";
  const productImage = imageMap[product.id];

  // Process title for specific formatting
  const titleWords = product.title.split(' ');
  let processedTitle;
  if (titleWords[1] && titleWords[1].toLowerCase() === 'currant') {
    processedTitle = (
      <>
        <div>{titleWords.slice(0, 2).join(' ')}</div>
        <div>{titleWords.slice(2).join(' ')}</div>
      </>
    );
  } else {
    processedTitle = (
      <>
        <div>{titleWords[0]}</div>
        <div>{titleWords.slice(1).join(' ')}</div>
      </>
    );
  }

  // Bold 'chia seeds' and italic 'wow' in the product description
  const boldedDescription = product.description.split(/(chia seeds|wow)/gi).map((segment, index) => {
    if (segment.toLowerCase() === 'chia seeds') {
      return <span key={index} className="font-bold">chia seeds</span>;
    }
    if (segment.toLowerCase() === 'wow') {
      return <span key={index} className="italic">wow</span>;
    }
    return <span key={index}>{segment}</span>;
  });

  return (
    <div className="pm w-full md:h-auto md:pr-[5%] pr-[0%]" style={{ backgroundColor: bgColor }}>
      <div className='productCards flex h-full md:flex-row flex-col justify-center items-center pt-[8%]'>
        <div className='leftSection md:w-auto w-full flex flex-1 flex-col md:justify-start justify-end md:pl-[10vw] px-[10vw] pr-[5%] md:pb-[10vh] pb-[10%] md:pt-[0px] mt-[8%]'>
                    {/* Move rightSection below h1 for mobile screens */}
          <div className='md:hidden rightSection flex flex-1 items-center w-full justify-center md:w-auto md:py-[0%] py-[10%]'>
            <BottleInCircle image={productImage} />
          </div>
          <h1 className="text-otherWhiteishText text-[12vw] md:text-[5vw] leading-[90%] font-bold">
            {processedTitle}
          </h1>

          <h2 className="text-otherWhiteishText text-[4.7vw] md:text-[2.5vw] h-fit">
            {styledSubtitle}
          </h2>
          <p className='md:max-w-[35vw] text-[3.5vw] md:text-[1.5vw] font-medium text-otherWhiteishText pb-[2vh] pt-[1vh] leading-[110%] md:leading-[133%]'>
            {boldedDescription}          
          </p>
          <button className="md:h-[5vh] h-[2.5vh] md:w-[12vw] w-[20vw] rounded-[100px] bg-white" onClick={handleReadMore}>
            <h4 className={`${textColorClass} md:text-[1.2vw] text-[2vw] font-bold w-full flex items-center justify-between px-[1vw]`}>
              Read More 
              <img src={arrowImage} alt="arrow" className="ml-2 md:w-[1.5vw] w-[2vw] md:h-[4vh] h-[2vh]" />
            </h4>
          </button>
        </div>

        {/* Default rightSection positioning for larger screens */}
        <div className='hidden md:flex rightSection flex-1 items-center w-full md:w-auto md:py-[0%] py-[10%]'>
          <BottleInCircle image={productImage} />
        </div>
      </div>
    </div>
  );
}

export default ProductMain;
