import React from 'react';
import { Helmet } from 'react-helmet';

function SEO({ title, description, keywords, image, url }) {
  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords.join(', ')} />

      {/* Open Graph Meta Tags for Social Media */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {image && <meta property="og:image" content={image} />}
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />

      {/* Facebook Meta Tags */}
      <meta property="og:site_name" content="ChiaDia" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:image:alt" content={description} />

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {image && <meta name="twitter:image" content={image} />}

      {/* Canonical URL */}
      <link rel="canonical" href={url} />

      {/* Robots Meta Tag */}
      <meta name="robots" content="index, follow" />

      {/* Author Meta Tag */}
      <meta name="author" content="ChiaDia" />

      {/* Viewport Meta Tag */}
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      {/* Structured Data (JSON-LD) */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "ChiaDia",
          "url": url,
          "logo": image,
          "description": description,
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+38970210192",
            "contactType": "Customer Service"
          },
          "sameAs": [
            "https://www.facebook.com/chiadiadrinks",
            "https://www.instagram.com/chiadiadrinks/",
            "https://www.linkedin.com/company/chiad%C3%ADa/"
          ],
        })}
      </script>
    </Helmet>
  );
}

export default SEO;
