import React from 'react';
import insta from '../../assets/contact/insta.png'; 
import fb from '../../assets/contact/fb.png'; 
import linkedin from '../../assets/contact/linkedin.png'; 

import collagePlaceholder from '../../assets/contact/collagePlaceholder.png'; 
import './styles/contact.css';

function Social() {
  return (
    <div className="so w-screen h-auto md:my-[80px] my-[8%]">
      <div className='w-full h-full flex flex-col items-center justify-center'>
        <div className='icons flex flex-col justify-center items-center'>
          <h2 className='text-blackishText md:text-[120px] text-[11vw] font-bold text-center'>Follow Us</h2>
          <h5 className='text-ogGreenText md:text-[58px] text-[4vw] font-bold text-center pt-[px] md:max-w-[55%] w-[90%] leading-[90%]'>
            Join us on social media for the latest news, updates and glimpse behind the scenes at ChiaDia.
          </h5>
          <div className='h-auto w-auto flex justify-center md:space-x-[76px] space-x-[9vw] md:pt-[75px] pt-[10%]'>
            <a 
              href="https://www.instagram.com/chiadiadrinks/" 
              target="_blank" 
              rel="noopener noreferrer"
              className='md:w-[165px] md:h-[165px] w-[50px] h-[50px] rounded-full bg-ogGreenText flex items-center justify-center'
            >
              <img
                src={insta}
                alt="Instagram Icon"
                className="object-contain md:w-auto w-[48%]"
              />
            </a>
            <a 
              href="https://www.facebook.com/chiadiadrinks" 
              target="_blank" 
              rel="noopener noreferrer"
              className='md:w-[165px] md:h-[165px] w-[50px] h-[50px] rounded-full bg-circleRed flex items-center justify-center'
            >
              <img
                src={fb}
                alt="Facebook Icon"
                className="object-contain w-[26%]"
              />
            </a>
            <a 
              href="https://www.linkedin.com/company/chiad%C3%ADa/" 
              target="_blank" 
              rel="noopener noreferrer"
              className='md:w-[165px] md:h-[165px] w-[50px] h-[50px] rounded-full bg-circleYellow flex items-center justify-center'
            >
              <img
                src={linkedin}
                alt="LinkedIn Icon"
                className="object-contain w-[48%]"
              />
            </a>
          </div>
        </div>

        {/*<div className='collage w-full h-auto pt-[157px]'>
          <div className='collage-track flex'>
            <img
              src={collagePlaceholder}
              alt="Collage Placeholder"
              className=""
            />
            <img
              src={collagePlaceholder}
              alt="Collage Placeholder"
              className=""
            />
            <img
              src={collagePlaceholder}
              alt="Collage Placeholder"
              className=""
            />
            <img
              src={collagePlaceholder}
              alt="Collage Placeholder"
              className=""
            />
            <img
              src={collagePlaceholder}
              alt="Collage Placeholder"
              className=""
            />
          </div>
        </div>*/}
      </div>
    </div>
  );
}

export default Social;
